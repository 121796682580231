export function zeroFill(value: string, length: number): string {
  while (value.length < length) {
    value = "0" + value;
  }
  return value;
}

export function ciEquals(a: string, b: string): boolean {
  return typeof a === "string" && typeof b === "string"
    ? a.localeCompare(b, undefined, {sensitivity: "accent"}) === 0
    : a === b;
}

// IE11 does not support startsWith and endsWith
export function startsWith(value: string, prefix: string): boolean {
  return value.indexOf(prefix) === 0;
}
