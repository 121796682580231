import Alert, {AlertSeverity} from "../../common/alert";
import formatDate from "../../../common/format-date";
import LoadingView from "../../common/loading-view";
import React, {Component, ReactElement} from "react";
import ReleaseFiles from "./release-files";
import {CurrentRelease} from "../../../service/domain/releases";
import {i} from "../../../locale";
import {IServices} from "../../../service/services";

export interface LastReleasesGalleryProps {
  services: IServices;
}

export default class LastReleasesGallery extends Component<LastReleasesGalleryProps> {
  renderMainInfo(item: CurrentRelease): ReactElement {
    return (
      <h2>
        <span
          title={item.countryName}
          className={
            "country-flag flag-icon flag-icon-" +
            item.countryCode.toLowerCase()
          }
        ></span>
        <span className="release-name">{item.releaseName}</span>&nbsp;
        <span className="release-category">{item.categoryId}</span>
      </h2>
    );
  }

  renderReleases(releases: CurrentRelease[]): ReactElement {
    const {services} = this.props;

    return (
      <>
        {releases.map((info) => {
          return (
            <section key={info.releaseId} className="release-info">
              {this.renderMainInfo(info)}
              <LoadingView
                provider={() =>
                  services.releases.getReleaseById(info.releaseId)
                }
                then={(release) => {
                  return (
                    <div>
                      {release === null && (
                        <Alert
                          title="Release not found"
                          message="The release was not found"
                          severity={AlertSeverity.info}
                        />
                      )}
                      {release !== null && (
                        <>
                          {release.description && <p>{release.description}</p>}
                          <ReleaseFiles
                            details={release}
                            disableStats={true}
                          />
                        </>
                      )}
                    </div>
                  );
                }}
              />
              <em>Published at: {formatDate(info.publishTime)}</em>
            </section>
          );
        })}
      </>
    );
  }

  render(): ReactElement {
    const {services} = this.props;
    return (
      <div>
        <div className="presentation">
          <h1>Current releases</h1>
        </div>
        <LoadingView
          provider={services.releases.getCurrentReleases}
          then={(releases) => {
            return (
              <div>
                <p>
                  Here you can see all releases to which you have access, by
                  organization membership. All HCPs in your organizations have
                  access to the same releases.
                </p>
                {releases.length === 0 && (
                  <Alert
                    title={i().Messages.NoReleasesConfiguredTitle}
                    message={i().Messages.NoReleasesConfigured}
                    severity={AlertSeverity.info}
                  />
                )}
                {releases.length > 0 && this.renderReleases(releases)}
              </div>
            );
          }}
        />
      </div>
    );
  }
}
