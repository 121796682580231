import {
  aad_loginRequest,
  aad_msalConfig,
  b2c_loginRequest,
  b2c_msalConfig,
} from "./config";
import {CommonAuthorizationUrlRequest} from "@azure/msal-common";
import {User} from "../../../service/user";
import {userFromAccessToken} from "./tokens";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

const aad_app = new PublicClientApplication(aad_msalConfig);
const b2c_app = new PublicClientApplication(b2c_msalConfig);

export type SsoSilentRequest = Partial<
  Omit<
    CommonAuthorizationUrlRequest,
    "responseMode" | "codeChallenge" | "codeChallengeMethod"
  >
>;

const storage = localStorage;
const TOKEN_STORAGE_KEY = "ID_TOKEN";

export function clearSession(): void {
  storage.removeItem(TOKEN_STORAGE_KEY);

  const toRemove: string[] = [];

  for (const x in storage) {
    if (
      x.indexOf("b2c_1a_kisei_signin") > -1 ||
      x.indexOf("login.windows.net") > -1
    ) {
      toRemove.push(x);
    }
  }

  for (const item of toRemove) {
    storage.removeItem(item);
  }
}

export function hasStoredToken(): boolean {
  return TOKEN_STORAGE_KEY in storage;
}

export function aad_login(): Promise<User> {
  return new Promise((resolve, reject) => {
    aad_app
      .loginPopup(aad_loginRequest)
      .then((resp) => {
        if (resp !== null) {
          storage.setItem(TOKEN_STORAGE_KEY, resp.idToken);

          const user = userFromAccessToken(resp.idToken);

          if (user) {
            resolve(user);
          } else {
            reject("The JWT could not be used");
          }
        } else {
          reject("Response is null");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function aad_logout(): Promise<void> {
  return new Promise((resolve, reject) => {
    aad_app
      .logoutPopup()
      .then(() => {
        clearSession();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function b2c_logout(): Promise<void> {
  return new Promise((resolve, reject) => {
    b2c_app
      .logoutPopup()
      .then(() => {
        clearSession();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function b2c_login(): Promise<User> {
  return new Promise((resolve, reject) => {
    b2c_app
      .loginPopup(b2c_loginRequest)
      .then((resp) => {
        if (resp !== null) {
          storage.setItem(TOKEN_STORAGE_KEY, resp.idToken);

          const user = userFromAccessToken(resp.idToken);

          if (user) {
            resolve(user);
          } else {
            reject("The JWT could not be used");
          }
        } else {
          reject("Response is null");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function userFromStorage(): User | null {
  const stored = storage.getItem(TOKEN_STORAGE_KEY);

  if (!stored) {
    return null;
  }

  return userFromAccessToken(stored);
}

function _loginSilent(
  app: PublicClientApplication,
  loginHint: string
): Promise<User | null> {
  // Tries to obtain a new access token silently, for the user with the given
  // login hint (email address).
  // If it fails for most reasons, it return null (the user will be requested
  // to repeat login).
  return new Promise((resolve, reject) => {
    app
      .ssoSilent({
        loginHint,
      })
      .then((resp) => {
        if (resp !== null) {
          storage.setItem(TOKEN_STORAGE_KEY, resp.idToken);

          const user = userFromAccessToken(resp.idToken);

          if (user) {
            resolve(user);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // perfectly fine
          resolve(null);
        } else {
          reject(error);
        }
      });
  });
}

function aad_loginSilent(loginHint: string): Promise<User | null> {
  return _loginSilent(aad_app, loginHint);
}

function b2c_loginSilent(loginHint: string): Promise<User | null> {
  return _loginSilent(b2c_app, loginHint);
}

export function loginSilent(user: User): Promise<User | null> {
  if (user.isAADUser()) {
    return aad_loginSilent(user.email);
  }

  if (user.isB2CUser()) {
    return b2c_loginSilent(user.email);
  }

  return new Promise((resolve) => {
    resolve(null);
  });
}
