import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {ReleaseHistory} from "../../../service/domain/releases";
import {IServices} from "../../../service/services";
import Panel from "../../common/panel";
import ReleaseHistoryTable from "./release-history-table";

export interface ReleaseHistoryProps {
  releaseId: string;
  services: IServices;
}

export interface ReleaseHistoryState {
  loading: boolean;
  error?: ApplicationError;
  history: ReleaseHistory[];
}

export default class ReleaseHistoryView extends Component<
  ReleaseHistoryProps,
  ReleaseHistoryState
> {
  constructor(props: ReleaseHistoryProps) {
    super(props);

    this.state = {
      loading: true,
      history: [],
    };
  }

  componentDidMount(): void {
    this.load();
  }

  load(): void {
    const releases = this.props.services.releases;

    this.setState({
      loading: true,
      error: undefined,
    });
    releases.getReleaseById(this.props.releaseId).then(
      (release) => {
        this.setState({
          history: release ? release.history : [],
          loading: false,
        });
      },
      (error) => {
        this.setState({
          error,
          loading: false,
        });
      }
    );
  }

  render(): ReactElement {
    const {error, loading, history} = this.state;
    return (
      <Panel error={error} loading={loading}>
        <ReleaseHistoryTable history={history} />
      </Panel>
    );
  }
}
