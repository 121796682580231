import {OneCustomerBaseURL as BaseURL} from ".";
import {PersonsAPI} from "../domain/persons";
import {post} from "../../common/fetch";

export default class OneCustomerPersonsAPI implements PersonsAPI {
  updatePerson(
    id: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    return post<void>(`${BaseURL}/person/${id}`, {
      firstName,
      lastName,
    });
  }
}
