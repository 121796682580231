import React, {Component, ReactElement} from "react";
import {formatBytes} from "../../../common/bytes";
import formatDate from "../../../common/format-date";
import {ReleaseNode} from "../../../service/domain/releases";
import {
  ItemAction,
  ItemProperty,
  StaticTable,
} from "../../common/tables/tables";
import FileDownloadLink from "./file-download-link";

export interface ReleaseFilesTableProps {
  nodes: ReleaseNode[];
  actions: Array<ItemAction<ReleaseNode>>;
}

function getProperties(): ItemProperty<ReleaseNode>[] {
  return [
    {
      id: "name",
      label: "Name",
      notSortable: true,
      render: (item: ReleaseNode) => {
        return (
          <FileDownloadLink releaseId={item.releaseId} node={item.node}>
            item.node.name
          </FileDownloadLink>
        );
      },
    },
    {
      id: "type",
      label: "Type",
      notSortable: true,
      render: (item: ReleaseNode) => {
        return item.node.type;
      },
    },
    {
      id: "size",
      label: "Size",
      notSortable: true,
      render: (item: ReleaseNode) => {
        return formatBytes(item.node.size);
      },
    },
    {
      id: "creationTime",
      label: "Uploaded at",
      notSortable: true,
      render: (item: ReleaseNode) => {
        return formatDate(item.node.creationTime);
      },
    },
  ];
}

// eslint-disable-next-line max-len
export default class ReleaseFilesTable extends Component<ReleaseFilesTableProps> {
  render(): ReactElement {
    const {actions, nodes} = this.props;

    return (
      <StaticTable<ReleaseNode>
        properties={getProperties()}
        defaultSortOrder="asc"
        defaultSortProperty="name"
        actions={actions}
        items={nodes}
      />
    );
  }
}
