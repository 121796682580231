import EditOutlined from "@material-ui/icons/EditOutlined";
import EditPersonForm, {PersonUpdate} from "../persons/person-edit-form";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {addErrorToDialog, dismissDialog} from "../view-functions";
import {ApplicationError} from "../../../common/errors";
import {getInitialOrganizationId} from "../hcp-admin-utils";
import {HearingCareProfessionalContext} from "../../../service/domain/hcps";
import {IServices} from "../../../service/services";
import {PaginatedSet} from "../../../service/domain/lists";
import {PersonsAPI} from "../../../service/domain/persons";
import {
  EmployeesAPI,
  EmployeeTableItem,
} from "../../../service/domain/employees";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";

import {
  DynamicTable,
  ItemProperty,
  ItemAction,
} from "../../common/tables/tables";
import HCPsFilters, {HCPsFiltersState} from "./hcps-filters";
import {DialogSize} from "../../common/dialogs/size";
import formatDate from "../../../common/format-date";
import {Link} from "react-router-dom";
import PersonalField from "./personal-field";
import Copy from "../../common/copy";

export interface HCPsTableProps {
  services: IServices;
  context: HearingCareProfessionalContext;
}

export interface HCPsTableState {
  loading: boolean;
  filters: HCPsFiltersState;
  confirm: ConfirmDialogProps;
}

function getProperties(table: HCPsTable): ItemProperty<EmployeeTableItem>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: EmployeeTableItem) => {
        return item.id;
      },
    },
    {
      id: "email",
      label: "Email",
      render: (item: EmployeeTableItem) => {
        const email = item.email;
        return <Copy text={email} />;
      },
    },
    {
      id: "firstName",
      label: "First name",
      notSortable: true,
      render: (item: EmployeeTableItem) => {
        return (
          <PersonalField
            value={item.firstName}
            anonymous={!item.hasPI}
            onShowClick={() => {
              table.loadPersonalInformation(item);
            }}
          />
        );
      },
    },
    {
      id: "lastName",
      label: "Last name",
      notSortable: true,
      render: (item: EmployeeTableItem) => {
        return (
          <PersonalField
            value={item.lastName}
            anonymous={!item.hasPI}
            onShowClick={() => {
              table.loadPersonalInformation(item);
            }}
          />
        );
      },
    },
    {
      id: "role",
      label: "Role",
      render: (item: EmployeeTableItem) => {
        const name = item.role === "Admin" ? "HCP Admin" : "HCP";
        return <span className="hcp-role">{name}</span>;
      },
    },
    {
      id: "creationTime",
      label: "Creation time",
      render: (item: EmployeeTableItem) => {
        if (item.creationTime === "0001-01-01T00:00:00") {
          return "";
        }
        return formatDate(item.creationTime);
      },
    },
  ];
}

function exportTransform(item: EmployeeTableItem): any {
  return {
    id: item.id,
    personId: item.personId,
    firstName: item.firstName,
    lastName: item.lastName,
    countryCode: item.countryCode,
    organization: item.organization.name + `(${item.organization.id})`,
    email: item.email,
    role: item.role,
    creationTime: item.creationTime,
  };
}

export default class HCPsTable extends Component<
  HCPsTableProps,
  HCPsTableState
> {
  private table: React.RefObject<
    DynamicTable<EmployeeTableItem, HCPsFiltersState>
  >;

  constructor(props: HCPsTableProps) {
    super(props);

    // TODO: store filters in memory ~~
    this.state = {
      loading: false,
      filters: {
        search: "",
        organizationId: getInitialOrganizationId(props.context),
      },
      confirm: closedDialog(),
    };
    this.table = React.createRef();
  }

  public get employeesService(): EmployeesAPI {
    return this.props.services.employees;
  }

  public get personsService(): PersonsAPI {
    return this.props.services.persons;
  }

  remove(item: EmployeeTableItem): void {
    this.setState({
      loading: true,
    });

    this.employeesService.removeEmployee(item.id).then(
      () => {
        dismissDialog(this);
        // refresh the table
        this.table.current?.load();
      },
      (error: ApplicationError) => {
        addErrorToDialog(this, error);
      }
    );
  }

  async loadPersonalInformation(item: EmployeeTableItem): Promise<void> {
    if (item.hasPI) {
      return;
    }
    const {services} = this.props;
    try {
      const tableState = this.table.current?.state;

      if (!tableState) {
        return;
      }

      const data = await services.employees.getPersonInformation(
        item.personId
      );

      const matchingRecords = tableState.items.filter(
        (record) => record.personId === item.personId
      );

      for (const record of matchingRecords) {
        record.hasPI = true;
        record.firstName = data.firstName;
        record.lastName = data.lastName;
        record.countryCode = data.countryCode;
      }

      this.table.current?.refresh();
    } catch (error) {
      alert("An error occurred while fetching personal information.");
    }
  }

  handlePersonUpdate(update: PersonUpdate, input: EmployeeTableItem): void {
    input.firstName = update.firstName;
    input.lastName = update.lastName;

    // Note: if the user is sorting by first name or last name,
    // the item could disappear from the list if we reloaded, but we don't
    // force reload because it would be confusing.
    dismissDialog(this);
  }

  fetch(
    page: number,
    sortBy: string,
    filters: HCPsFiltersState
  ): Promise<PaginatedSet<EmployeeTableItem>> {
    // do not filter by role, to display HCP Admins and HCPs together
    return this.employeesService.getEmployees(
      page,
      sortBy,
      filters.search,
      "", // brand id: always Oticon Medical by definition in this app
      filters.organizationId,
      undefined
    );
  }

  onFiltersChange(filters: HCPsFiltersState): void {
    this.setState({
      filters,
    });
  }

  private prepareItems(data: PaginatedSet<EmployeeTableItem>): void {
    data.items.forEach((item) => {
      if (item.role === "Joined") {
        item.role = "HCP";
      }
    });
  }

  render(): ReactElement {
    const {context} = this.props;
    const {loading, confirm, filters} = this.state;

    return (
      <div className="relative">
        {loading && <Loader className="covering" />}
        <div className="presentation">
          <h1>User overview</h1>
          <p>
            Here you can manage the list of HCPs who can already use the
            software download platform, by membership to your same
            organizations.{" "}
            <Link to="/invitations/new">
              To invite new HCPs, use the invitation form.
            </Link>
          </p>
        </div>
        <HCPsFilters
          context={context}
          search={filters.search}
          organizationId={filters.organizationId}
          onChange={this.onFiltersChange.bind(this)}
        />
        <DynamicTable<EmployeeTableItem, HCPsFiltersState>
          items={[]}
          defaultSortOrder="desc"
          defaultSortProperty="creationTime"
          properties={getProperties(this)}
          filters={filters}
          provider={this.fetch.bind(this)}
          actions={this.getActions()}
          onDataFetched={this.prepareItems.bind(this)}
          exportTransform={exportTransform}
          exportFileName="professionals.xlsx"
          ref={this.table}
        />
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  getActions(): Array<ItemAction<EmployeeTableItem>> {
    return [
      {
        title: "Edit",
        icon: <EditOutlined />,
        onClick: this.onEditClick.bind(this),
      },
      {
        title: "Remove",
        icon: <HighlightOff />,
        onClick: this.onRemoveClick.bind(this),
        condition: (item: EmployeeTableItem): boolean => {
          return !/admin/i.test(item.role);
        },
      },
    ];
  }

  async onEditClick(item: EmployeeTableItem): Promise<void> {
    if (!item.hasPI) {
      this.setState({
        loading: true,
      });
      await this.loadPersonalInformation(item);
      this.setState({
        loading: false,
      });
    }

    this.setState({
      confirm: {
        open: true,
        title: "Edit",
        description: "",
        fragment: (
          <EditPersonForm
            service={this.props.services.persons}
            id={item.personId}
            firstName={item.firstName}
            lastName={item.lastName}
            onUpdate={(update) => this.handlePersonUpdate(update, item)}
            onCancel={() => dismissDialog(this)}
          />
        ),
        close: () => {
          return;
        },
        confirm: () => {
          return;
        },
        noButtons: true,
      },
    });
  }

  getUserDisplayName(item: EmployeeTableItem): string {
    const parts: string[] = [];

    if (item.firstName && item.lastName) {
      parts.push(item.firstName);
      parts.push(item.lastName);
    }

    if (item.email) {
      parts.push(`(${item.email})`);
    }

    return parts.join(" ");
  }

  getOrganizationDisplayName(item: EmployeeTableItem): string {
    const organization = item.organization;

    if (!organization) {
      return "";
    }

    if (organization.name && organization.number) {
      return `${organization.name} ${organization.number}`;
    }

    return organization.name || organization.number || "the organization";
  }

  onRemoveClick(item: EmployeeTableItem): void {
    this.setState({
      confirm: {
        open: true,
        title:
          `Remove user ${this.getUserDisplayName(item)} ` +
          `from ${item.organization.name}?`,
        description:
          "If confirmed, this HCP won't have access to " +
          `${this.getOrganizationDisplayName(item)} anymore.`,
        close: () => dismissDialog(this),
        confirm: () => this.remove(item),
        size: DialogSize.medium,
      },
    });
  }
}
