import CategorySelect from "../categories/category-select";
import CountriesSelect from "../countries/countries-select";
import Form from "../../common/forms/form";
import formatDate from "../../../common/format-date";
import React, {Component, ReactElement} from "react";
import ReleaseDescriptionField from "./release-description-field";
import ReleaseNameField from "./release-name-field";
import {ApplicationError, ConflictError} from "../../../common/errors";
import {i} from "../../../locale";
import {IServices} from "../../../service/services";
import {ReleaseDetails} from "../../../service/domain/releases";
import {delay} from "lodash";
export interface EditReleaseProps {
  services: IServices;
  details: ReleaseDetails;
  onUpdate: (updated: ReleaseDetails) => void;
}

export interface EditReleaseState {
  waiting: boolean;
  error: ApplicationError | null;
}

export default class EditRelease extends Component<
  EditReleaseProps,
  EditReleaseState
> {
  private userInteracted: boolean;
  private nameField: React.RefObject<ReleaseNameField>;
  private descriptionField: React.RefObject<ReleaseDescriptionField>;
  private countriesSelect: React.RefObject<CountriesSelect>;
  private categorySelect: React.RefObject<CategorySelect>;

  constructor(props: EditReleaseProps) {
    super(props);

    this.userInteracted = false;
    this.nameField = React.createRef();
    this.descriptionField = React.createRef();
    this.countriesSelect = React.createRef();
    this.categorySelect = React.createRef();

    this.state = {
      waiting: false,
      error: null,
    };
  }

  async validate(): Promise<boolean> {
    const results = await Promise.all([
      this.nameField.current?.validate(),
      this.descriptionField.current?.validate(),
    ]);

    return results.every((item) => item === true);
  }

  equivalent(): boolean {
    const {details} = this.props;

    return (
      details.name === this.nameField.current?.value &&
      details.description === this.descriptionField.current?.value &&
      details.categoryId === this.categorySelect.current?.value.id &&
      details.countries
        .map((item) => item.countryId)
        .sort()
        .join(",") ===
        (this.countriesSelect.current?.ids || []).sort().join(",")
    );
  }

  confirm(): void {
    if (this.state.waiting || this.equivalent() || !this.userInteracted)
      return;

    const nameField = this.nameField.current;

    if (nameField) {
      nameField.user_interaction = true;
    }

    this.validate().then((valid) => {
      if (!valid) {
        return;
      }

      const {details} = this.props;

      this.setState({
        error: null,
        waiting: true,
      });

      this.props.services.releases
        .updateRelease({
          categoryId: this.categorySelect.current?.value.id || "",
          id: details.id,
          name: this.nameField.current?.value || "",
          description: this.descriptionField.current?.value || "",
          countries: this.countriesSelect.current?.ids || null,
          eTag: details.eTag,
          draft: details.draft,
        })
        .then(
          (data) => {
            this.setState({
              error: null,
              waiting: false,
            });

            this.props.onUpdate(data);
          },
          (error: ApplicationError) => {
            if (error instanceof ConflictError) {
              this.nameField.current?.setError(
                "A release already exists with this name"
              );
              this.setState({
                waiting: false,
              });
              return;
            }
            error.retry = () => {
              this.confirm();
            };
            this.setState({
              error,
              waiting: false,
            });
          }
        );
    });
  }

  onCancelClick(): void {
    const {details} = this.props;

    const nameField = this.nameField.current;
    const descriptionField = this.descriptionField.current;
    const countriesSelect = this.countriesSelect.current;

    if (nameField) nameField.value = details.name;
    if (descriptionField) descriptionField.value = details.description;
    if (countriesSelect)
      countriesSelect.setCountries(
        details.countries.map((item) => item.countryId)
      );
  }

  autoSubmit(): void {
    if (!this.userInteracted) {
      return;
    }
    delay(() => {
      this.confirm();
    }, 0);
  }

  setUserInteracted(): void {
    this.userInteracted = true;
  }

  render(): ReactElement {
    const {details} = this.props;
    const {waiting, error} = this.state;

    return (
      <Form waiting={waiting} error={error} onSubmit={() => this.confirm()}>
        <dl onClick={() => this.setUserInteracted()}>
          <dt>ID</dt>
          <dd>{details.id}</dd>
          <dt>Created at</dt>
          <dd>{formatDate(details.creationTime)}</dd>
          <dt>Last update time</dt>
          <dd>{formatDate(details.updateTime)}</dd>
          <dt>{i().Name}</dt>
          <dd className="full-w">
            <ReleaseNameField
              ref={this.nameField}
              value={details.name}
              onEdit={() => this.autoSubmit()}
            />
          </dd>
          <dt>Description</dt>
          <dd className="full-w">
            <ReleaseDescriptionField
              ref={this.descriptionField}
              value={details.description}
              onEdit={() => this.autoSubmit()}
            />
          </dd>
          <dt>Category</dt>
          <dd>
            <CategorySelect
              service={this.props.services.categories}
              value={details.categoryId}
              onChange={() => this.autoSubmit()}
              ref={this.categorySelect}
            />
          </dd>
          <dt>Countries</dt>
          <dd>
            <CountriesSelect
              service={this.props.services.countries}
              selected={details.countries.map((item) => item.countryId)}
              onSelect={() => this.autoSubmit()}
              ref={this.countriesSelect}
            />
          </dd>
        </dl>
      </Form>
    );
  }
}
