import {PaginatedSet} from "./lists";

export interface OrganizationTableItem {
  id: string;
  name: string | null;
  number: string | null;
  creationTime: string | null;
  updateTime: string | null;
}

export interface OrganizationsAPI {
  getOrganizations(
    page: number,
    category: string,
    country: string,
    sortBy: string,
    search: string
  ): Promise<PaginatedSet<OrganizationTableItem>>;
}

export function getOrganizationDisplayValue(
  item: OrganizationTableItem
): string {
  return `${item.name} (${item.id})`;
}
