import React, {Component, ReactElement} from "react";
import {FileInfo, isImage} from "../../../common/files";
import FileImagePreview from "./file-image-preview";
import {Button, LinearProgress} from "@material-ui/core";
import Loader from "../loader";
import ErrorPanel from "../error";
import {UserCancelledOperation} from "../../../common/errors";
import {IServices} from "../../../service/services";
import {FileUploadData} from "../../../service/domain/blobs";
import {CreateNodeInput} from "../../../service/domain/releases";

export interface FileUploadProps {
  releaseId: string;
  services: IServices;
  file: FileInfo;
  onRemoveClick: () => void;
}

export interface FileUploadState {
  uploading: boolean;
  progress: number;
  error: any;
  done: boolean;
}

export default class FileUpload extends Component<
  FileUploadProps,
  FileUploadState
> {
  constructor(props: FileUploadProps) {
    super(props);

    this.state = {
      uploading: false,
      progress: 0,
      error: null,
      done: false,
    };
  }

  get uploading(): boolean {
    return this.state.uploading;
  }

  get done(): boolean {
    return this.state.done;
  }

  canStartUpload(): boolean {
    return this.uploading === false && this.done === false;
  }

  onProgress(progress: number): void {
    this.setState({
      progress,
    });
  }

  async upload(): Promise<CreateNodeInput | undefined> {
    if (this.uploading) {
      return;
    }
    const filesService = this.props.services.blobs;
    const {releaseId, file} = this.props;
    this.setState({
      uploading: true,
      progress: 0,
    });

    let fileInfo: FileUploadData | null = null;

    try {
      const data = await filesService.initializeFileUpload({
        containerId: releaseId,
        fileName: file.name,
        fileType: file.mime,
        fileSize: file.size,
      });

      fileInfo = await filesService.uploadFile(file.htmlFile, data, this);
    } catch (error) {
      if (error instanceof UserCancelledOperation) {
        // nothing wrong
        this.setState({
          uploading: false,
          done: false,
          progress: 0,
        });

        throw error;
      }
      this.setState({
        uploading: false,
        error: {},
        done: false,
      });
    }

    // now store node information
    if (fileInfo !== null) {
      this.setState({
        uploading: false,
        progress: 100,
        done: true,
      });

      return {
        fileId: fileInfo.fileId,
        fileName: file.name,
        fileSize: fileInfo.fileSize,
        fileType: fileInfo.fileType,
      };
    }
  }

  onCancelUploadClick(): void {
    this.setState({
      uploading: false,
    });
  }

  render(): ReactElement {
    const {file} = this.props;
    const {done, uploading, progress, error} = this.state;
    const fileError = file.error;

    return (
      <>
        <div className={"file-input" + (fileError ? " ui-error" : "")}>
          <div className="file-graphics">
            {isImage(file) && <FileImagePreview file={file.htmlFile} />}
          </div>
          <div className="file-info">
            <span className="file-name">{file.name}</span>
            {uploading && <Loader className="mini" />}
            <span className="file-size">{file.getSizeRepr()}</span>
            {(uploading || done) && (
              <>
                <LinearProgress
                  variant="determinate"
                  className="progress-bar"
                  value={progress}
                />
                {done === false && (
                  <em className="file-upload-progress">
                    Upload in progress...
                  </em>
                )}
                {done === true && (
                  <em className="file-upload-progress">
                    The file was uploaded
                  </em>
                )}
              </>
            )}
            {error && <ErrorPanel error={error} />}
            {fileError && <span className="ui-file-error">{fileError}</span>}
          </div>
          <div className="buttons">
            {uploading === false && done === false && (
              <Button
                color="secondary"
                onClick={() => this.props.onRemoveClick()}
              >
                Remove
              </Button>
            )}
            {uploading === true && (
              <Button
                color="secondary"
                onClick={() => this.onCancelUploadClick()}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}
