export function getLocaleTimeStamp(timeOnly: boolean = false): string {
  const now = new Date();

  try {
    if (timeOnly) {
      return now.toLocaleTimeString();
    }
    return `${now
      .toLocaleDateString()
      .replace("/", "-")} ${now.toLocaleTimeString()}`;
  } catch {
    return `${now.getTime()}`;
  }
}
