import ServiceSettings from "../../../service/settings";

// https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-net-web-browsers
// https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-auth-code

// This is beautiful:
// it is sufficient to create an app registration, add SPA as platform
// with a redirect URL, and the app is already ready to issue access tokens

export const aad_msalConfig = {
  auth: {
    clientId: ServiceSettings.clientId,
    authority: ServiceSettings.authority,
    redirectUri: ServiceSettings.redirectURL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// b2c_1a_kisei_signin

export const b2c_msalConfig = {
  auth: {
    clientId: ServiceSettings.b2cClientId,
    authority: ServiceSettings.b2cAuthority,
    redirectUri: ServiceSettings.redirectURL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const b2c_loginRequest = {
  scopes: ["openid", "profile"],
};

export const aad_loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

export const aad_tokenRequest = {
  scopes: ["User.Read", "Mail.Read"],
};
