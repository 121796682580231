import React, {Component, ReactElement, ChangeEvent} from "react";
import Select from "@material-ui/core/Select";
import {NamedItem} from "./select-named";
import {getSelectedValues} from "../../../common/dom";
import {Chip} from "@material-ui/core";

export interface MultiSelectProps<T> {
  items: T[];
  onSelect: (item: T[]) => void;
  initialValue?: string[];
  disabled?: boolean;
}

interface MultiSelectState {
  selectedItemsIds: string[];
}

/**
 * Generic component to select multiple items in an HTML select.
 * Items are given as props.
 */
export default class MultiNamedSelect<T extends NamedItem> extends Component<
  MultiSelectProps<T>,
  MultiSelectState
> {
  constructor(props: MultiSelectProps<T>) {
    super(props);

    this.state = {
      selectedItemsIds: props.initialValue || [],
    };
  }

  getSelectedItems(): T[] {
    const {selectedItemsIds} = this.state;
    return this.props.items.filter(
      (obj) => selectedItemsIds.indexOf(obj.id) > -1
    );
  }

  setSelected(ids: string[]): void {
    this.setState({selectedItemsIds: ids});

    const {items} = this.props;
    const selectedItems = items.filter((item) => ids.indexOf(item.id) > -1);
    this.props.onSelect(selectedItems);
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    const select = event.target;
    const selectedValues = getSelectedValues(select as HTMLSelectElement);

    const {items} = this.props;
    const selectedItems = items.filter(
      (item) => selectedValues.indexOf(item.id) > -1
    );

    this.props.onSelect(selectedItems);
    this.setState({
      selectedItemsIds: selectedValues,
    });
  }

  render(): ReactElement {
    const {disabled, items} = this.props;
    const {selectedItemsIds} = this.state;
    const getSelectedItems = this.getSelectedItems();

    if (items.length === 0) {
      return <i>...</i>;
    }

    // Note: if items contain a single element, then select it automatically
    if (items.length === 1) {
      const singleItem = items[0];
      return <i>{singleItem.name}</i>;
    }

    return (
      <div className="multi-select-wrapper">
        <Select
          native
          value={selectedItemsIds}
          name="selectedItemId"
          onChange={this.onChange.bind(this)}
          disabled={disabled}
          multiple={true}
        >
          {items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
        <div className="multi-select-summary">
          {getSelectedItems.map((item) => (
            <Chip key={item.id} label={item.name} />
          ))}
        </div>
      </div>
    );
  }
}
