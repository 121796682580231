import HighlightOff from "@material-ui/icons/HighlightOff";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {InvitationTableItem} from "../../../service/domain/invitations";
import {PaginatedSet} from "../../../service/domain/lists";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import {ApplicationError} from "../../../common/errors";
import formatDate from "../../../common/format-date";

import {
  DynamicTable,
  ItemProperty,
  ItemAction,
} from "../../common/tables/tables";
import {IServices} from "../../../service/services";
import {addErrorToDialog, dismissDialog} from "../view-functions";
import {HearingCareProfessionalContext} from "../../../service/domain/hcps";
import {getInitialOrganizationId} from "../hcp-admin-utils";
import Copy from "../../common/copy";
import HCPsFilters from "../hcps/hcps-filters";
import {getOrganizationDisplayValue} from "../../../service/domain/organizations";

interface InvitationsTableFilter {
  search: string;
  organizationId: string;
}

const Properties: ItemProperty<InvitationTableItem>[] = [
  {
    id: "id",
    label: "Id",
    notSortable: true,
    render: (item: InvitationTableItem) => {
      return item.id;
    },
  },
  {
    id: "email",
    label: "Email",
    render: (item: InvitationTableItem) => {
      return <Copy text={item.email} />;
    },
  },
  {
    id: "creationTime",
    label: "Creation time",
    render: (item: InvitationTableItem) => {
      return formatDate(item.creationTime);
    },
  },
  {
    id: "culture",
    label: "Culture",
    render: (item: InvitationTableItem) => {
      return item.cultureCode;
    },
  },
  {
    id: "role",
    label: "Role",
    render: (item: InvitationTableItem) => {
      return item.role;
    },
  },
  {
    id: "status",
    label: "Status",
    render: (item: InvitationTableItem) => {
      return item.status;
    },
  },
  {
    id: "organizationName",
    label: "Organization name",
    render: (item: InvitationTableItem) => {
      return item.organization?.name;
    },
  },
  {
    id: "organizationNumber",
    label: "Organization number",
    render: (item: InvitationTableItem) => {
      return item.organization?.number;
    },
  },
  {
    id: "confirmationTime",
    label: "Confirmation time",
    render: (item: InvitationTableItem) => {
      return formatDate(item.confirmationTime);
    },
  },
];

export interface InvitationsTableProps {
  services: IServices;
  context: HearingCareProfessionalContext;
}

export interface InvitationsTableState {
  loading: boolean;
  filters: InvitationsTableFilter;
  confirm: ConfirmDialogProps;
}

function exportTransform(item: InvitationTableItem): any {
  const organization = item.organization;
  return {
    id: item.id,
    cultureCode: item.cultureCode,
    email: item.email,
    status: item.status,
    role: item.role,
    senderId: item.senderId,
    creationTime: item.creationTime,
    confirmationTime: item.confirmationTime,
    organization: organization
      ? getOrganizationDisplayValue(organization)
      : null,
  };
}
export default class InvitationsTable extends Component<
  InvitationsTableProps,
  InvitationsTableState
> {
  private table: React.RefObject<
    DynamicTable<InvitationTableItem, InvitationsTableFilter>
  >;

  constructor(props: InvitationsTableProps) {
    super(props);

    this.state = {
      loading: false,
      filters: {
        search: "",
        organizationId: getInitialOrganizationId(props.context),
      },
      confirm: closedDialog(),
    };
    this.table = React.createRef();
  }

  private prepareItems(data: PaginatedSet<InvitationTableItem>): void {
    data.items.forEach((item) => {
      if (item.role === "Joined") {
        if (item.organization !== null) {
          // user invited as HCP
          item.role = "HCP";
        } else {
          item.role = "Customer"; // ?? TODO: not sure about this
        }
      }
    });
  }

  onFiltersChange(filters: InvitationsTableFilter): void {
    this.setState({
      filters,
    });
  }

  remove(item: InvitationTableItem): void {
    this.setState({
      loading: true,
    });

    this.props.services.invitations.deleteInvitation(item.id).then(
      () => {
        dismissDialog(this);
        this.table.current?.load();
      },
      (error: ApplicationError) => {
        addErrorToDialog(this, error);
      }
    );
  }

  getDeleteDescription(item: InvitationTableItem): string {
    if (item.status === "ACTIVE") {
      return (
        "If confirmed, the recipient won`t be able to accept the " +
        "invitation."
      );
    }

    return "This action cannot be undone.";
  }

  onDeleteClick(item: InvitationTableItem): void {
    this.setState({
      confirm: {
        open: true,
        title: `Delete invitation for "${item.email}"?`,
        description: this.getDeleteDescription(item),
        close: () => dismissDialog(this),
        confirm: () => this.remove(item),
      },
    });
  }

  fetch(
    page: number,
    sortBy: string,
    filters: InvitationsTableFilter
  ): Promise<PaginatedSet<InvitationTableItem>> {
    return this.props.services.invitations.getInvitations(
      page,
      sortBy,
      filters.search,
      "",
      filters.organizationId
    );
  }

  render(): ReactElement {
    const {context} = this.props;
    const {loading, confirm, filters} = this.state;

    return (
      <div className="relative">
        {loading && <Loader className="covering" />}
        <div className="presentation">
          <h1>Invitations overview</h1>
          <p>
            Here you can manage the invitations that were sent to HCPs, to
            grant them access to the software download platform.
          </p>
        </div>
        <HCPsFilters
          context={context}
          search={filters.search}
          organizationId={filters.organizationId}
          onChange={this.onFiltersChange.bind(this)}
        />
        <DynamicTable<InvitationTableItem, InvitationsTableFilter>
          items={[]}
          defaultSortOrder="desc"
          defaultSortProperty="creationTime"
          properties={Properties}
          filters={filters}
          provider={this.fetch.bind(this)}
          actions={this.getActions()}
          onDataFetched={this.prepareItems.bind(this)}
          exportTransform={exportTransform}
          exportFileName="invitations.xlsx"
          ref={this.table}
        />
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  getActions(): Array<ItemAction<InvitationTableItem>> {
    return [
      {
        title: "Delete",
        icon: <HighlightOff />,
        onClick: this.onDeleteClick.bind(this),
      },
    ];
  }
}
