import React, {Component, ReactElement} from "react";
import {Services} from "../../../service/services";
import LoadingView from "../../common/loading-view";

export default class About extends Component {
  render(): ReactElement {
    return (
      <div>
        <h3>Software distribution platform for Oticon Medical.</h3>
        <LoadingView
          provider={Services.info.getAppInfo}
          then={(appInfo) => {
            return (
              <div>
                <dl>
                  <dt>Version</dt>
                  <dd>{appInfo.version}</dd>
                  <dt>Build number</dt>
                  <dd>{appInfo.buildNumber}</dd>
                  <dt>Contact email</dt>
                  <dd>
                    <a
                      href={"mailto:" + appInfo.contactEmail}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {appInfo.contactEmail}
                    </a>
                  </dd>
                </dl>
              </div>
            );
          }}
        />
      </div>
    );
  }
}
