import {Button} from "@material-ui/core";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {ReleaseDetails, ReleaseNode} from "../../../service/domain/releases";
import {IServices} from "../../../service/services";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import {DialogSize} from "../../common/dialogs/size";
import Upload from "../../common/forms/upload";
import {ItemAction} from "../../common/tables/tables";
import {dismissDialog} from "../view-functions";
import ReleaseFilesTable from "./release-files-table";

export interface ReleaseFilesProps {
  services: IServices;
  details: ReleaseDetails;
  onUpdate: (release: ReleaseDetails) => void;
}

export interface ReleaseFilesState {
  loading: boolean;
  confirm: ConfirmDialogProps;
  error?: ApplicationError;
}

export default class ReleaseFiles extends Component<
  ReleaseFilesProps,
  ReleaseFilesState
> {
  constructor(props: ReleaseFilesProps) {
    super(props);

    this.state = {
      loading: false,
      confirm: closedDialog(),
    };
  }

  onUploadClick(): void {
    const {details} = this.props;

    this.setState({
      confirm: {
        open: true,
        title: "Upload files",
        description: "",
        size: DialogSize.full,
        fragment: (
          <Upload
            releaseId={this.props.details.id}
            services={this.props.services}
            onUpload={(data) => {
              this.props.onUpdate(data);
            }}
            onCancel={() => {
              dismissDialog(this);
            }}
            details={details}
          />
        ),
        close: () => {
          return;
        },
        confirm: () => {
          return;
        },
        noButtons: true,
      },
    });
  }

  onFileRemoveClick(item: ReleaseNode): void {
    this.setState({
      confirm: {
        open: true,
        title: "Remove the file from this release?",
        description:
          `If confirmed, ${item.node.name} will be ` +
          "removed from this release.",
        close: () => {
          dismissDialog(this);
        },
        confirm: () => {
          if (this.state.loading) {
            return;
          }
          this.setState({
            loading: true,
            error: undefined,
          });
          const {details, services} = this.props;
          services.releases
            .deleteNodes(details.id, [
              {
                id: item.id,
              },
            ])
            .then(
              (updatedRelease) => {
                this.props.onUpdate(updatedRelease);
                dismissDialog(this);
              },
              (error) => {
                this.setState({
                  loading: false,
                  error,
                });
              }
            );
        },
      },
    });
  }

  getActions(): Array<ItemAction<ReleaseNode>> {
    return [
      {
        title: "Unlink the file",
        icon: <i className="fas fa-trash-alt"></i>,
        onClick: this.onFileRemoveClick.bind(this),
      },
    ];
  }

  render(): ReactElement {
    const {details} = this.props;
    const {confirm} = this.state;
    const nodes = details.nodes;

    return (
      <div>
        {nodes.length === 0 && (
          <div>
            <p>The release has no files configured.</p>
            <Button onClick={() => this.onUploadClick()}>
              upload the first files.
            </Button>
          </div>
        )}
        {nodes.length > 0 && (
          <>
            <ReleaseFilesTable nodes={nodes} actions={this.getActions()} />
            <div className="buttons-area">
              <Button onClick={() => this.onUploadClick()}>
                upload more files
              </Button>
            </div>
          </>
        )}
        <ConfirmDialog {...confirm} />
      </div>
    );
  }
}
