import AssignmentInd from "@material-ui/icons/AssignmentInd";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import React, {ReactElement} from "react";
import {i} from "../../locale";
import {Link} from "react-router-dom";
import {User} from "../../service/user";

interface MenuItem {
  path: string;
  text: string;
  icon: ReactElement;
  auth?: (user: User) => boolean;
}

function getMenu(items: MenuItem[]): ReactElement {
  return (
    <div>
      {items.map((item) => (
        <Link to={item.path} key={item.path}>
          <ListItem button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        </Link>
      ))}
    </div>
  );
}

export function getMainMenu(user: User): ReactElement {
  const releasesMenuItem: MenuItem = {
    path: "/releases",
    text: i().Menu.Dashboard,
    icon: <DashboardIcon />,
  };
  const downloadsMenuItem: MenuItem = {
    path: "/downloads",
    text: i().Menu.Downloads,
    icon: <GetAppIcon />,
  };
  const hcpsMenuItem: MenuItem = {
    path: "/hearing-care-professionals",
    text: i().Menu.HCPs,
    icon: <PeopleAltIcon />,
  };
  const invitationsMenuItem: MenuItem = {
    path: "/invitations",
    text: i().Menu.Invitations,
    icon: <EmailIcon />,
  };

  let mainItems: MenuItem[] = [];
  if (user.isAADUser()) {
    if (user.isOMHQ()) {
      mainItems = [releasesMenuItem, downloadsMenuItem];
    } else if (user.isLME()) {
      mainItems = [releasesMenuItem, downloadsMenuItem];
    }
  }

  if (user.isB2CUser()) {
    if (user.hasRole("HCP-Admin")) {
      mainItems = [
        hcpsMenuItem,
        invitationsMenuItem,
        releasesMenuItem,
        downloadsMenuItem,
      ];
    } else if (user.hasRole("HCP")) {
      mainItems = [releasesMenuItem];
    }
  }

  return getMenu(
    mainItems.filter((item) => item.auth === undefined || item.auth(user))
  );
}

export function getSecondaryMenu(): ReactElement {
  return getMenu([
    {
      path: "/account",
      text: i().Menu.Account,
      icon: <AssignmentInd />,
    },
    {
      path: "/about",
      text: i().Menu.About,
      icon: <i className="fas fa-info-circle"></i>,
    },
  ]);
}
