import React, {Component, ReactElement} from "react";
import {IconButton} from "@material-ui/core";

export interface ClearButtonProps {
  hidden?: boolean;
  clear: () => void;
}

export default class ClearButton extends Component<ClearButtonProps> {
  onClearSelectionClick(): void {
    this.props.clear();
  }

  render(): ReactElement {
    const {hidden} = this.props;
    return (
      <>
        {this.props.children}
        {!hidden && (
          <IconButton
            className="input-clear"
            onClick={() => this.onClearSelectionClick()}
          >
            <i className="far fa-minus-square"></i>
          </IconButton>
        )}
      </>
    );
  }
}
