export interface Country {
  id: string;
  name: string;
}

export interface CountriesAPI {
  getCountries(): Promise<Country[]>;
}

export function getCountriesDict(
  countries: Country[]
): {[key: string]: Country} {
  const dict: {[key: string]: Country} = {};

  for (const country of countries) {
    dict[country.id] = country;
  }

  return dict;
}
