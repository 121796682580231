import React, {Component, ReactElement} from "react";
import {User} from "../../service/user";
import {ROLES} from "../../service/roles";
import Head from "../common/head";
import PreferencesRegion from "./account/account-preferences";
import {Button} from "@material-ui/core";
import {b2c_logout, aad_logout} from "./auth";
import {HCPContext} from "./account/hcp-context";

export interface AccountProps {
  user: User;
}

export default class Account extends Component<AccountProps> {
  async logout(): Promise<void> {
    const {user} = this.props;

    if (user.isB2CUser()) {
      await b2c_logout();
    } else {
      await aad_logout();
    }

    window.location.reload();
  }

  getRoleDisplayName(role: string): string {
    // TODO: rename Joined or fix on the server side
    if (role === "Joined") {
      return "HCP";
    }
    return role;
  }

  renderOrganizations(): ReactElement {
    const {user} = this.props;

    if (user.isAADUser()) {
      return <></>;
    }

    return (
      <HCPContext.Consumer>
        {(context) =>
          context !== null && (
            <section className="dt-strong">
              <h2>Your organizations:</h2>
              <p>This is the list of organizations to which you belong:</p>
              <ul>
                {context.membership.map((item) => {
                  return (
                    <li className="item-description" key={item.id}>
                      <strong>
                        {item.organizationName}({item.organizationNumber});
                      </strong>
                      &nbsp;
                      <span>
                        your role:{" "}
                        <strong>{this.getRoleDisplayName(item.role)}</strong>
                      </span>
                      &emsp;
                      <em>Organization id: {item.organizationId}</em>
                      <br />
                      <em>Markets: {item.marketCodes.join(", ")}</em>
                      {item.categoryId && (
                        <em>&emsp; Category: {item.categoryId}</em>
                      )}
                    </li>
                  );
                })}
              </ul>
            </section>
          )
        }
      </HCPContext.Consumer>
    );
  }

  renderDynamicRole(role: string): ReactElement {
    if (role.startsWith("Market_")) {
      const market = role.replace("Market_", "");
      return (
        <React.Fragment key={market}>
          <dt>{market}</dt>
          <dd>This role grants you access to {market} country.</dd>
        </React.Fragment>
      );
    }

    if (role.startsWith("Cat_")) {
      const category = role.replace("Cat_", "");
      return (
        <React.Fragment key={category}>
          <dt>{category}</dt>
          <dd>This role grants you access to items related to {category}.</dd>
        </React.Fragment>
      );
    }

    return <React.Fragment key={role}></React.Fragment>;
  }

  render(): ReactElement {
    const {user} = this.props;
    return (
      <div className="page">
        <Head title="Account"></Head>
        <section>
          <h2>Information</h2>
          <dl>
            <dt>Email</dt>
            <dd>{user.email}</dd>
          </dl>
        </section>
        <section className="dt-strong">
          <h2>Your roles</h2>
          {user.roles.length === 0 && <p>You don`t have any specific role.</p>}
          {user.roles.length > 0 && (
            <dl>
              {user.roles.map((role) => {
                const roleInfo = ROLES[role as keyof typeof ROLES];

                if (roleInfo === undefined) {
                  return this.renderDynamicRole(role);
                }

                return (
                  <React.Fragment key={roleInfo.displayName}>
                    <dt>{roleInfo.displayName}</dt>
                    <dd>{roleInfo.description}</dd>
                  </React.Fragment>
                );
              })}
            </dl>
          )}
        </section>
        {this.renderOrganizations()}
        <PreferencesRegion />
        <section className="log-out-region">
          <Button onClick={() => this.logout()}>Sign-out</Button>
        </section>
      </div>
    );
  }
}

export function getAccountPage(user: User): () => ReactElement {
  return (): ReactElement => <Account user={user} />;
}
