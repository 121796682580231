import CountryFilter from "../countries/country-select";
import debounce from "lodash/debounce";
import React, {Component, ReactElement} from "react";
import TextInput from "../../common/forms/text-input";
import {Country} from "../../../service/domain/countries";
import {IServices} from "../../../service/services";
import HCPOrganizationSelect from "../hcps/hcp-organization-select";
import {HCPContext} from "../account/hcp-context";
import {NamedItem} from "../../common/forms/select-named";

export interface DownloadsFiltersProps {
  search: string;
  countryId: string;
  services: IServices;
  onChange: (filters: DownloadsFiltersState) => void;
  onCountriesLoaded: (countries: Country[]) => void;
}

export interface DownloadsFiltersState {
  search: string;
  country: string;
  organizationId: string | null;
}

export default class DownloadsFilters extends Component<
  DownloadsFiltersProps,
  DownloadsFiltersState
> {
  constructor(props: DownloadsFiltersProps) {
    super(props);

    this.state = {
      search: props.search,
      country: props.countryId,
      organizationId: null,
    };
  }

  onFiltersChange = debounce(
    () => {
      this.props.onChange(this.state);
    },
    250,
    {leading: false, trailing: true}
  );

  onFiltersChangeNoDelay(): void {
    this.props.onChange(this.state);
  }

  onCountrySelect(item: Country | null): void {
    this.setState({
      country: item ? item.id : "",
    });

    this.onFiltersChangeNoDelay();
  }

  onOrganizationSelect(item: NamedItem | null): void {
    const currentValue = this.state.organizationId;
    const newValue = item ? item.id : "";
    if (currentValue === newValue) {
      return;
    }
    this.setState({
      organizationId: item ? item.id : "",
    });

    this.onFiltersChangeNoDelay();
  }

  onSearchChange(search: string): void {
    const currentValue = this.state.search;
    if (currentValue === search) {
      return;
    }
    this.setState({
      search,
    });

    this.onFiltersChange();
  }

  render(): ReactElement {
    const {countryId, services} = this.props;
    const {search} = this.state;

    return (
      <div className="filters-region">
        <dl>
          <dt>
            <label>Search</label>
          </dt>
          <dd>
            <TextInput
              value={search}
              onChange={(value) => this.onSearchChange(value)}
            />
          </dd>
          <HCPContext.Consumer>
            {(context) => (
              <>
                <CountryFilter
                  service={services.countries}
                  onSelect={this.onCountrySelect.bind(this)}
                  onLoaded={(values) => this.props.onCountriesLoaded(values)}
                  initialValue={countryId}
                  allowEmptyForced={true}
                />
                {context !== null && (
                  <>
                    <dt>Organization</dt>
                    <dd>
                      <HCPOrganizationSelect
                        context={context}
                        onOrganizationSelect={this.onOrganizationSelect.bind(
                          this
                        )}
                      ></HCPOrganizationSelect>
                    </dd>
                  </>
                )}
              </>
            )}
          </HCPContext.Consumer>
        </dl>
      </div>
    );
  }
}
