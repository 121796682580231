import React, {Component, ReactElement} from "react";
import {Country, CountriesAPI} from "../../../service/domain/countries";
import DynamicSelect from "../../common/forms/select-named-dynamic";

export interface CountrySelectProps {
  service: CountriesAPI;
  onSelect: (item: Country | null) => void;
  onLoaded?: (items: Country[]) => void;
  initialValue?: string;
  disabled?: boolean;
  allowEmptyForced?: boolean;
}

export default class CountrySelect extends Component<CountrySelectProps> {
  render(): ReactElement {
    const {
      allowEmptyForced,
      initialValue,
      disabled,
      onSelect,
      onLoaded,
    } = this.props;
    return (
      <>
        <dt>Country</dt>
        <dd className="select-wrapper">
          <DynamicSelect<Country>
            load={this.props.service.getCountries}
            onSelect={onSelect}
            onLoaded={onLoaded}
            initialValue={initialValue}
            disallowEmpty={false}
            disabled={disabled}
            allowEmptyForced={allowEmptyForced}
          />
        </dd>
      </>
    );
  }
}
