import {InterfaceError} from "../../common/errors";
import {HearingCareProfessionalContext} from "../../service/domain/hcps";

export function getInitialOrganizationId(
  context: HearingCareProfessionalContext
): string {
  const firstOrg = context.membership.find((item) => item.role === "Admin");

  if (!firstOrg) {
    // TODO: this can happen if the user has the HCP-Admin role but was removed
    // from all organizations; TODO: handle this situation!!
    throw new InterfaceError(
      "This component is for HCP Admins only, so the user " +
        "must be an administrator of at least one organization"
    );
  }

  return firstOrg.organizationId;
}
