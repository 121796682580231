import React, {Component, ReactElement} from "react";
import ReleaseOrganizationsSelect from "../organizations/organizations-select";
import {ApplicationError} from "../../../common/errors";
import {Button} from "@material-ui/core";
import {DialogSize} from "../../common/dialogs/size";
import {dismissDialog} from "../view-functions";
import {IServices} from "../../../service/services";
import {
  ReleaseDetails,
  ReleaseOrganization,
} from "../../../service/domain/releases";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import ReleaseOrganizationsTable from "./release-organizations-table";
import {ItemAction} from "../../common/tables/tables";
import Loader from "../../common/loader";

export interface ReleaseOrganizationsFormProps {
  services: IServices;
  details: ReleaseDetails;
  onUpdate: (release: ReleaseDetails) => void;
}

export interface ReleaseOrganizationsFormState {
  loading: boolean;
  confirm: ConfirmDialogProps;
  error?: ApplicationError;
}

export default class ReleaseOrganizationsForm extends Component<
  ReleaseOrganizationsFormProps,
  ReleaseOrganizationsFormState
> {
  constructor(props: ReleaseOrganizationsFormProps) {
    super(props);

    this.state = {
      loading: false,
      confirm: closedDialog(),
    };
  }

  onBindClick(): void {
    const {details, services} = this.props;

    this.setState({
      confirm: {
        open: true,
        title: "Bind organizations to the release",
        description: "",
        size: DialogSize.full,
        fragment: (
          <ReleaseOrganizationsSelect
            details={details}
            services={services}
            onUpdate={(release) => {
              dismissDialog(this);
              this.props.onUpdate(release);
            }}
            onCancel={() => {
              dismissDialog(this);
            }}
          />
        ),
        close: () => {
          return;
        },
        confirm: () => {
          return;
        },
        noButtons: true,
      },
    });
  }

  onOrganizationRemoveClick(item: ReleaseOrganization): void {
    if (this.state.loading) {
      return;
    }
    this.setState({
      loading: true,
      error: undefined,
    });
    const {details, services} = this.props;
    services.releases
      .unbindReleaseFromOrganizations(details.id, [item.organizationId])
      .then(
        (updatedRelease) => {
          this.props.onUpdate(updatedRelease);
          dismissDialog(this);
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        }
      );
  }

  getActions(): Array<ItemAction<ReleaseOrganization>> {
    return [
      {
        title: "Remove the bound",
        icon: <i className="fas fa-trash-alt"></i>,
        onClick: this.onOrganizationRemoveClick.bind(this),
      },
    ];
  }

  render(): ReactElement {
    const {details} = this.props;
    const {confirm, loading} = this.state;
    const organizations = details.organizations;

    return (
      <div className="release-organizations-region">
        {loading && <Loader className="covering" />}
        <p>
          Releases can be assigned to specific organizations. The HCPs
          belonging to those organizations will have access to the latest
          release assigned to the organization.
        </p>
        {organizations.length === 0 && (
          <div>
            <Button onClick={() => this.onBindClick()}>
              Bind to organizations
            </Button>
          </div>
        )}
        {organizations.length > 0 && (
          <>
            <ReleaseOrganizationsTable
              items={details.organizations}
              actions={this.getActions()}
            />
            <div className="buttons-area">
              <Button onClick={() => this.onBindClick()}>
                Bind to organizations
              </Button>
            </div>
          </>
        )}
        <ConfirmDialog {...confirm} />
      </div>
    );
  }
}
