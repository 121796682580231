import {getAboutPage} from "./about";
import {getAccountPage} from "./account";
import {getDownloadsPage} from "./downloads";
import {getReleasePage} from "./release";
import {getReleasesPage} from "./releases";
import {getHCPsPage} from "./hcps";
import {ReactElement} from "react";
import {User} from "../../service/user";
import InvitationsPage from "./invitations/invitations-page";
import {getLastReleasesPage} from "./releases/last-releases-page";
import Home from "./home";

interface Route {
  path: string;
  exact: boolean;
  main: () => ReactElement;
  auth?: (user: User) => boolean;
}

function _getRoutes(user: User): Route[] {
  const adminLoginRoute: Route = {
    path: "/admin-login",
    exact: true,
    main: () => Home(),
  };
  const homeRoute: Route = {
    path: "/",
    exact: true,
    main: () => Home(),
  };
  const aboutRoute: Route = {
    path: "/about",
    exact: false,
    main: getAboutPage(),
  };
  const accountRoute: Route = {
    path: "/account",
    exact: false,
    main: getAccountPage(user),
  };
  const releaseDetailsPage: Route = {
    path: "/release",
    exact: false,
    main: getReleasePage(user),
  };
  const lastReleasesRoute: Route = {
    path: "/releases",
    exact: false,
    main: getLastReleasesPage(user),
  };
  const downloadsRoute: Route = {
    path: "/downloads",
    exact: false,
    main: getDownloadsPage(user),
  };
  const hcpsRoute: Route = {
    path: "/hearing-care-professionals",
    exact: false,
    main: getHCPsPage(user),
  };
  const invitationsRoute: Route = {
    path: "/invitations",
    exact: false,
    main: InvitationsPage,
  };

  if (user.isAADUser()) {
    if (user.isOMHQ()) {
      // NOTE: LME do not administer releases
      return [
        adminLoginRoute,
        homeRoute,
        {
          path: "/releases",
          exact: false,
          main: getReleasesPage(user),
        },
        releaseDetailsPage,
        downloadsRoute,
        accountRoute,
        aboutRoute,
      ];
    }

    return [
      adminLoginRoute,
      homeRoute,
      releaseDetailsPage,
      lastReleasesRoute,
      downloadsRoute,
      accountRoute,
      aboutRoute,
    ];
  }

  if (user.isB2CUser()) {
    if (user.hasRole("HCP-Admin")) {
      return [
        adminLoginRoute,
        homeRoute,
        hcpsRoute,
        releaseDetailsPage,
        invitationsRoute,
        lastReleasesRoute,
        downloadsRoute,
        accountRoute,
        aboutRoute,
      ];
    } else if (user.hasRole("HCP")) {
      return [adminLoginRoute, lastReleasesRoute, accountRoute, aboutRoute];
    }
  }

  return [adminLoginRoute, accountRoute, aboutRoute];
}

export function getRoutes(user: User): Route[] {
  return _getRoutes(user).filter(
    (item) => item.auth === undefined || item.auth(user)
  );
}
