import Query from "../../common/query";
import {OneCustomerBaseURL} from ".";
import {get} from "../../common/fetch";
import {PaginatedSet} from "../domain/lists";
import {
  OrganizationTableItem,
  OrganizationsAPI,
} from "../domain/organizations";
import ServiceSettings from "../settings";
import {cache} from "../../common/caching";

export default class OneCustomerOrganizationsAPI implements OrganizationsAPI {
  @cache(2)
  getOrganizations(
    page: number,
    category: string,
    country: string,
    sortBy: string,
    search: string
  ): Promise<PaginatedSet<OrganizationTableItem>> {
    const query = Query.write({
      page,
      search,
      sortBy,
      brand: ServiceSettings.oticonMedicalId,
      category,
    });

    return get<PaginatedSet<OrganizationTableItem>>(
      `${OneCustomerBaseURL}/organizations${query}`,
      {
        "X-Market": country,
      }
    );
  }
}
