export interface UserOptions {
  name: string;
  oid: string;
  iss: string;
  preferred_username: string;
  exp: number;
  roles: string[];
  given_name?: string;
  emails?: string[];
  extension_kisei_role?: string;
  extension_user_country?: string;
  extension_user_region?: string;
  tfp?: string;
}

export function isEnabledForApp(user: User): boolean {
  if (user.isAADUser()) {
    return user.hasRole("OMHQ") || user.hasRole("LME");
  }

  if (user.isB2CUser()) {
    // Note: later it is also necessary to check if the user has membership
    return user.hasKiseiRole();
  }
  return false;
}

function getRoles(options: UserOptions | undefined): string[] {
  if (options === undefined) {
    return [];
  }
  if (options.extension_kisei_role) {
    const allRoles = options.extension_kisei_role.split(/,/g);
    // TODO: support dynamic roles like ORG:<UUID>
    return allRoles.filter((value) => /HCP/.test(value));
  }

  return options.roles || [];
}

export class User {
  private _options: UserOptions | null;
  private _roles: string[];
  private _superUser: boolean;

  constructor(options?: UserOptions) {
    if (options) {
      const roles = getRoles(options);
      this._options = options;
      this._roles = roles;
      this._superUser = roles.includes("ADMIN");
    } else {
      this._options = null;
      this._roles = [];
      this._superUser = false;
    }
  }

  get name(): string {
    return this._options?.name || "";
  }

  get issuer(): string {
    return this._options?.iss || "";
  }

  get email(): string {
    if (this.isB2CUser()) {
      const emails = this._options?.emails;
      if (emails && emails.length) {
        return emails[0];
      }
      return "";
    }
    return this.options?.preferred_username || "";
  }

  get options(): UserOptions | null {
    return this._options;
  }

  get roles(): string[] {
    return this._roles;
  }

  private getIssuerOrThrow(): string {
    const issuer = this.issuer;

    if (issuer === "") {
      throw new Error("Missing issuer");
    }

    return issuer;
  }

  isExpired(): boolean {
    if (!this._options) {
      return false;
    }
    const expiryDate = new Date(this._options.exp * 1000);
    return expiryDate < new Date();
  }

  isAADUser(): boolean {
    const issuer = this.getIssuerOrThrow();
    return issuer.indexOf("https://login.microsoftonline.com/") === 0;
  }

  isOMHQ(): boolean {
    return this.isAADUser() && this.roles.indexOf("OMHQ") > -1;
  }

  isLME(): boolean {
    return this.isAADUser() && this.roles.indexOf("LME") > -1;
  }

  isB2CUser(): boolean {
    const issuer = this.getIssuerOrThrow();
    return /b2clogin.com/i.test(issuer);
  }

  hasKiseiRole(): boolean {
    const options = this.options;
    return (
      options !== undefined && options?.extension_kisei_role !== undefined
    );
  }

  hasRole(role: string): boolean {
    if (this._superUser) {
      return true;
    }
    return this._roles.includes(role);
  }

  can(role: string): boolean {
    if (this._superUser) {
      return true;
    }
    return this.hasRole(role);
  }

  getCategoriesRoles(): string[] {
    return this.getRolesByPrefix("Cat_");
  }

  getMarketsRoles(): string[] {
    return this.getRolesByPrefix("Market_");
  }

  private getRolesByPrefix(prefix: string): string[] {
    const roles: string[] = [];
    for (const role of this._roles) {
      if (role.startsWith(prefix)) {
        roles.push(role.substring(prefix.length));
      }
    }
    return roles;
  }
}
