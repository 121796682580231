import About from "./info/about";
import Head from "../common/head";
import React, {ReactElement} from "react";
import {i} from "../../locale";

export function getAboutPage(): () => ReactElement {
  return (): ReactElement => (
    <div className="page">
      <Head title={i().Pages.About} />
      <h2>{i().Pages.About}</h2>
      <About />
    </div>
  );
}
