import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, {ReactElement} from "react";
import {aad_login, b2c_login} from "./auth";
import {User} from "../../service/user";
import ForgottenPasswordForm from "./account/forgotten-password-form";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../common/dialogs/confirm-dialog";
import {DialogSize} from "../common/dialogs/size";
import {dismissDialog} from "./view-functions";
import {ServicesContext} from "./services-context";

export interface LoginProps {
  onLogin: (user: User) => void;
}

export interface LoginState {
  showPasswordReset: boolean;
  loading: boolean;
  confirm: ConfirmDialogProps;
}

export default class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      showPasswordReset: false,
      loading: false,
      confirm: closedDialog(),
    };
  }

  onLoginClick(): void {
    aad_login().then(
      (user) => {
        this.props.onLogin(user);
      },
      () => {
        // ignore (can be interaction_in_progress error)
      }
    );
  }

  onHCPLoginClick(): void {
    b2c_login().then(
      (user) => {
        this.props.onLogin(user);
      },
      () => {
        // ignore (can be interaction_in_progress error)
      }
    );
  }

  onForgetPasswordClick(): void {
    this.setState({
      showPasswordReset: true,
      confirm: {
        open: true,
        title: "Did you forget your password?",
        size: DialogSize.medium,
        fragment: (
          <ServicesContext.Consumer>
            {(services) => (
              <ForgottenPasswordForm
                onCancel={() => {
                  dismissDialog(this);
                }}
                onDone={() => {
                  this.setState({
                    confirm: {
                      open: true,
                      title: "Password reset email delivered!",
                      description:
                        "You should soon receive an email with " +
                        "a link to reset your password. Please check your " +
                        "inbox.",
                      close: () => {
                        dismissDialog(this);
                      },
                    },
                  });
                }}
                services={services}
              />
            )}
          </ServicesContext.Consumer>
        ),
        close: () => {
          return;
        },
        confirm: () => {
          return;
        },
        noButtons: true,
      },
    });
  }

  render(): ReactElement {
    const aad = location.pathname.toLowerCase() === "/admin-login";
    const {confirm} = this.state;
    return (
      <Grid container component="main" className="login-screen">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className="login-image" />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <div className="login-banner">
            <div>
              <img id="oticon-medical-logo" src="/oticon-medical-logo.svg" />
              <span className="app-name">Software Center</span>
            </div>
          </div>
          <div className="jss3">
            <div className="login-form">
              {aad && (
                <Button
                  className="aad-sign-in-btn"
                  variant="contained"
                  onClick={() => this.onLoginClick()}
                >
                  Sign in for administrators
                </Button>
              )}
              {aad !== true && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => this.onHCPLoginClick()}
                  >
                    Sign in
                  </Button>
                  <br />
                  <button
                    className="fake-anchor forget-password"
                    onClick={() => this.onForgetPasswordClick()}
                  >
                    <em>Did you forget your password?</em>
                  </button>
                </>
              )}
            </div>
            <ConfirmDialog {...confirm} />
          </div>
        </Grid>
      </Grid>
    );
  }
}
