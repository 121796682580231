import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../common/errors";
import Panel from "./panel";

export interface LoadingViewProps<T> {
  provider: () => Promise<T>;
  then: (data: T) => ReactElement;
  mini?: boolean;
}

export interface LoadingViewState<T> {
  loading: boolean;
  error: ApplicationError | null;
  data: T | undefined;
}

export default class LoadingView<T> extends Component<
  LoadingViewProps<T>,
  LoadingViewState<T>
> {
  constructor(props: LoadingViewProps<T>) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      data: undefined,
    };

    this.load();
  }

  async load(): Promise<void> {
    try {
      const {provider} = this.props;
      const data = await provider();

      this.setState({
        data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  }

  render(): ReactElement {
    const {then, mini} = this.props;
    const {error, loading, data} = this.state;
    return (
      <Panel
        error={error}
        loading={loading}
        loaderClassName={mini ? "mini" : undefined}
      >
        {data !== undefined && then(data)}
      </Panel>
    );
  }
}
