import {BaseURL} from ".";
import {cache} from "../../common/caching";
import {get} from "../../common/fetch";
import {AppInfo, AppInfoAPI} from "../domain/info";

export default class KiseiAppInfoAPI implements AppInfoAPI {
  @cache()
  async getAppInfo(): Promise<AppInfo> {
    return get<AppInfo>(`${BaseURL}/api/info`);
  }
}
