import React, {Component, ReactElement, ChangeEvent} from "react";
import {TextField} from "@material-ui/core";
import {changeHandler} from "../../../common/forms";
import {splitEmailsCollectErrors} from "../../../common/emails";
import Alert, {AlertProps, AlertSeverity} from "../../common/alert";
import {debounce} from "lodash";

export interface EmailInputProps {
  id?: string;
  onChange: (values: string[]) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

export interface EmailInputState {
  error: boolean;
  value: string;
  helperText: string;
  problem?: AlertProps;
}

function errorLabel(errors: string[]): string {
  return errors.length === 1 ? "Invalid value: " : "Invalid values: ";
}

export interface EmailValidationOutput {
  emails: string[];
  errors: string[];
}

export default class EmailInput extends Component<
  EmailInputProps,
  EmailInputState
> {
  constructor(props: EmailInputProps) {
    super(props);

    this.state = {
      error: false,
      value: "",
      helperText: "",
    };
  }

  get value(): string {
    return this.state.value;
  }

  set value(newValue: string) {
    this.setState({value: newValue});
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    // user's interaction
    changeHandler.call(this, event);

    this.onValueChange();
  }

  onValueChange = debounce(
    () => {
      this.checkEmails(true);
    },
    250,
    {leading: false, trailing: true}
  );

  checkEmails(skipError?: boolean): EmailValidationOutput {
    const value = this.state.value;
    const errors: string[] = [];
    const emails = splitEmailsCollectErrors(value, errors);

    if (errors.length) {
      if (!skipError) {
        this.setState({
          error: true,
          helperText: `${errorLabel(errors)} "${errors
            .map((v) => `"${v}"`)
            .join(", ")}".`,
        });
      }
    } else {
      this.setState({
        error: false,
        helperText: "",
      });
    }

    if (emails.length > 1) {
      // TODO: support more than one email address
      // 2020.06.18 no time now to support more than one email at a time,
      // keep this for later since it's not high priority compared to other
      // features that need to be completed.
      // When there is time, make something well done:
      // * a table that displays success status for each recipient
      // * a semaphore to run invitations in parallel but not all together
      this.setState({
        problem: {
          title: "Feature not yet supported",
          message:
            "It is not yet supported to send multiple invitation " +
            "emails at once. This feature will be implemented after all " +
            "necessary features are done. For the time being, please insert " +
            "a single email address. Sorry for the inconvenience. " +
            "For more information, contact ``cloudheroes@demant.com``.",
          severity: AlertSeverity.warning,
        },
      });
    } else if (emails.length === 1) {
      this.setState({problem: undefined});
    }

    if (!errors.length) {
      this.setState({
        value: emails.join("; "),
      });
      this.props.onChange(emails);
    } else {
      // here we invalidate all emails,
      // it is unclear whether the user should be able to confirm even
      // when having some errors in the list and at least one good email
      // address
      this.props.onChange([]);
    }

    return {
      emails,
      errors,
    };
  }

  validateEmails(): boolean {
    const validation = this.checkEmails();
    return !!validation.emails.length && !validation.errors.length;
  }

  onBlur(): void {
    this.checkEmails();
  }

  render(): ReactElement {
    const {id, placeholder, autoFocus} = this.props;
    const {error, value, helperText, problem} = this.state;

    return (
      <React.Fragment>
        <TextField
          id={id}
          error={error}
          helperText={helperText}
          name="value"
          value={value}
          required
          autoComplete="off"
          onChange={this.onChange.bind(this)}
          onBlur={this.onBlur.bind(this)}
          variant="outlined"
          className="wide"
          placeholder={placeholder}
          inputRef={
            autoFocus
              ? (input) => input && setTimeout(() => input.focus(), 0)
              : undefined
          }
        />
        {problem && <Alert {...problem} />}
      </React.Fragment>
    );
  }
}
