import {getLocaleTimeStamp} from "./dates";

export function exportToExcel(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  records: any,
  fileName: string = "export.xlsx"
): any {
  if (!fileName) {
    fileName = "export.xlsx";
  }
  const XLSX = (window as any).XLSX;

  const timestamp = getLocaleTimeStamp(true);
  const wb = XLSX.utils.book_new();
  const workSheet = XLSX.utils.json_to_sheet(records);
  XLSX.utils.book_append_sheet(wb, workSheet, fileName);
  XLSX.writeFile(wb, timestamp + "-" + fileName);
}
