import Loader from "../../common/loader";
import Nullable from "../../common/nullable-value";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {IServices} from "../../../service/services";
import {OrganizationTableItem} from "../../../service/domain/organizations";
import {PaginatedSet} from "../../../service/domain/lists";
import OrganizationsFilterView, {
  OrganizationsFiltersState,
} from "./organizations-filters";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";

import {
  DynamicTable,
  ItemProperty,
  ItemAction,
} from "../../common/tables/tables";
import {
  getDefaultCategory,
  getDefaultCountry,
} from "../../common/user-functions";
import {User} from "../../../service/user";
import formatDate from "../../../common/format-date";

function getProperties(): ItemProperty<OrganizationTableItem>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: OrganizationTableItem) => {
        return item.id;
      },
    },
    {
      id: "name",
      label: "Name",
      render: (item: OrganizationTableItem) => {
        return <Nullable value={item.name}>{item.name}</Nullable>;
      },
    },
    {
      id: "number",
      label: "Number",
      render: (item: OrganizationTableItem) => {
        return <Nullable value={item.number}>{item.number}</Nullable>;
      },
    },
    {
      id: "creationTime",
      label: "Creation time",
      render: (item: OrganizationTableItem) => {
        return formatDate(item.creationTime);
      },
    },
    {
      id: "updateTime",
      label: "Last update time",
      render: (item: OrganizationTableItem) => {
        return formatDate(item.updateTime);
      },
    },
  ];
}

export interface OrganizationsTableProps {
  user: User;
  services: IServices;
  selectable?: boolean;
  onItemClick?: (item: OrganizationTableItem) => void;
  onItemsSelected?: (items: OrganizationTableItem[]) => void;
}

export interface OrganizationsTableState {
  loading: boolean;
  error?: ApplicationError;
  selectedItem?: OrganizationTableItem;
  filters: OrganizationsFiltersState;
  confirm: ConfirmDialogProps;
}

export default class OrganizationsTable extends Component<
  OrganizationsTableProps,
  OrganizationsTableState
> {
  private table: React.RefObject<
    DynamicTable<OrganizationTableItem, OrganizationsFiltersState>
  >;

  constructor(props: OrganizationsTableProps) {
    super(props);

    this.state = {
      loading: false,
      filters: {
        search: "",
        category: getDefaultCategory(props.user),
        country: getDefaultCountry(props.user),
      },
      confirm: closedDialog(),
    };
    this.table = React.createRef();
  }

  fetch(
    page: number,
    sortBy: string,
    filters: OrganizationsFiltersState
  ): Promise<PaginatedSet<OrganizationTableItem>> {
    return this.props.services.organizations.getOrganizations(
      page,
      filters.category,
      filters.country,
      sortBy,
      filters.search
    );
  }

  onFiltersChange(filters: OrganizationsFiltersState): void {
    this.setState({
      filters,
    });
  }

  onItemClick(item: OrganizationTableItem): void {
    this.setState({
      selectedItem: item,
    });
    if (this.props.onItemClick) {
      this.props.onItemClick(item);
    }
  }

  get selectedItems(): OrganizationTableItem[] {
    if (!this.table.current) {
      return [];
    }
    return this.table.current.state.selectedItems;
  }

  onSelect(items: OrganizationTableItem[]): void {
    if (this.props.onItemsSelected) {
      this.props.onItemsSelected(items);
    }
  }

  render(): ReactElement {
    const {selectable} = this.props;
    const {loading, confirm, filters} = this.state;

    return (
      <div className="relative">
        {loading && <Loader className="covering" />}
        <OrganizationsFilterView
          services={this.props.services}
          country={filters.country}
          category={filters.category}
          onChange={this.onFiltersChange.bind(this)}
        />
        <DynamicTable<OrganizationTableItem, OrganizationsFiltersState>
          items={[]}
          defaultSortOrder="desc"
          defaultSortProperty="updateTime"
          properties={getProperties()}
          filters={filters}
          provider={this.fetch.bind(this)}
          actions={this.getActions()}
          ref={this.table}
          onItemClick={this.onItemClick.bind(this)}
          onSelect={this.onSelect.bind(this)}
          selectable={selectable}
        />
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  getActions(): Array<ItemAction<OrganizationTableItem>> {
    return [];
  }
}
