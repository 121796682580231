import React, {Component, ReactElement} from "react";
import {UserContext} from "../app/user-context";
import {User} from "../../service/user";

export interface AuthProps {
  aad?: boolean;
  role?: string;
}

export default class Auth extends Component<AuthProps> {
  satisfied(user: User): boolean {
    if (this.props.aad) {
      // only for users from AAD
      return user.isAADUser();
    }

    if (!this.props.role) {
      throw new Error("Missing condition: either provide a role or provider.");
    }

    return user.can(this.props.role);
  }

  render(): ReactElement {
    return (
      <UserContext.Consumer>
        {(user) =>
          this.satisfied(user) && (
            <React.Fragment>{this.props.children}</React.Fragment>
          )
        }
      </UserContext.Consumer>
    );
  }
}
