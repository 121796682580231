import React, {Component, ReactElement} from "react";
import {Category, CategoriesAPI} from "../../../service/domain/categories";
import {NamedItem} from "../../common/forms/select-named";
import DynamicSelect from "../../common/forms/select-named-dynamic";

export interface CategorySelectProps {
  service: CategoriesAPI;
  value?: Category | string | null;
  readonly?: boolean;
  onChange?: (value: Category | null) => void;
  onLoaded?: (items: Category[]) => void;
  disallowEmpty?: boolean;
}

export interface CategorySelectState {
  error?: string;
  forceSelected?: Category[];
  disabled?: Array<Category | string>;
  selected: Category | null;
}

export default class CategorySelect extends Component<
  CategorySelectProps,
  CategorySelectState
> {
  private select: React.RefObject<DynamicSelect<Category>>;

  constructor(props: CategorySelectProps) {
    super(props);

    this.select = React.createRef();
    this.state = {selected: null};
  }

  async loadCategory(): Promise<Category[]> {
    return this.props.service.getCategories();
  }

  public get value(): Category {
    if (this.state.selected === null) {
      throw new Error("Category not selected.");
    }
    return this.state.selected;
  }

  onSelect(categoryNamedItem: NamedItem | null): void {
    const category =
      categoryNamedItem === null ? null : (categoryNamedItem as Category);
    this.setState({
      selected: category,
    });

    if (this.props.onChange) this.props.onChange(category);
  }

  render(): ReactElement {
    const {value, disallowEmpty, onLoaded} = this.props;
    const {error} = this.state;

    return (
      <React.Fragment>
        <DynamicSelect<Category>
          load={() => this.loadCategory()}
          initialValue={value || undefined}
          onLoaded={onLoaded}
          onSelect={(category) => this.onSelect(category)}
          disallowEmpty={disallowEmpty === undefined ? true : disallowEmpty}
          ref={this.select}
        />
        {error && <p className="ui-error">{error}</p>}
      </React.Fragment>
    );
  }
}
