import {BaseURL} from ".";
import {cache} from "../../common/caching";
import {get} from "../../common/fetch";
import {Country, CountriesAPI} from "../domain/countries";

export default class KiseiCountriesAPI implements CountriesAPI {
  @cache()
  async getCountries(): Promise<Country[]> {
    return get<Country[]>(`${BaseURL}/api/countries`);
  }
}
