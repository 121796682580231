export const ROLES = {
  OMHQ: {
    displayName: "OM HQ",
    description: `The OM HQ role grants you rights to create new releases across
    all markets, upload release files, review the activities of other users and
    to read global downloading status.`,
  },
  LME: {
    displayName: "Local Market",
    description: "This role grants you access to specific markets.",
  },
  KISEI: {
    displayName: "KISEI",
    description: "This role grants you basic access to the system.",
  },
  "HCP-Admin": {
    displayName: "HCP Admin",
    description: `This role grants you access to the system as administrator
    of other HCPs. You can manage the list of HCPs in your organization (HCB),
    invite new HCPs, and download the most up-to-date release files.`,
  },
  HCP: {
    displayName: "HCP",
    description: `This role grants you access to the download platform.
    You can download the most up-to-date release files.`,
  },
};
