export function storeOption(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function readStoredOption<T>(
  key: string,
  fn: (value: string) => T,
  defaultValue: T
): T {
  const storedValue = localStorage.getItem(key);

  if (!storedValue) {
    return defaultValue;
  }

  return fn(storedValue);
}

const CACHE: {[key: string]: any} = {};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function write(key: string, value: any): void {
  CACHE[key] = value;
}

export function read<T>(key: string): T | null {
  if (key in CACHE) {
    return CACHE[key] as T;
  }

  return null;
}

export default {
  write,
  read,
  storeOption,
  readStoredOption,
};
