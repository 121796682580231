import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import Loader from "../../common/loader";

export interface PersonalFieldProps {
  value: string;
  anonymous: boolean;
  onShowClick: () => void;
}

export interface PersonalFieldState {
  loading: boolean;
  error: ApplicationError | null;
}

export default class PersonalField extends Component<
  PersonalFieldProps,
  PersonalFieldState
> {
  constructor(props: PersonalFieldProps) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }

  onShowClick(): void {
    if (!this.props.anonymous) {
      return;
    }
    this.setState({
      loading: true,
    });
    this.props.onShowClick();
  }

  componentDidUpdate(): void {
    if (!this.props.anonymous && this.state.loading) {
      this.setState({
        loading: false,
      });
    }
  }

  render(): ReactElement {
    const {value, anonymous} = this.props;
    const {loading} = this.state;
    return (
      <span
        className={"personal-field " + (anonymous ? "anonymous" : "visible")}
        title="Click to display PI"
        onClick={() => this.onShowClick()}
      >
        <span className="value">{value}</span>
        {loading && <Loader className="mini" />}
      </span>
    );
  }
}
