import Release from "./releases/release";
import Head from "../common/head";
import React, {ReactElement} from "react";
import {AuthRouteConfiguration} from "./routing";
import {ServicesContext} from "./services-context";
import {Tabs} from "../common/tabs";
import {User} from "../../service/user";
import {useHistory, useParams} from "react-router-dom";
import {UserContext} from "./user-context";

function ReleasePage(): ReactElement {
  const {release_id} = useParams<{
    release_id: string;
  }>();
  const history = useHistory();
  return (
    <React.Fragment>
      <Head title="Release details" />
      <UserContext.Consumer>
        {(user) => (
          <ServicesContext.Consumer>
            {(services) => (
              <Release
                id={release_id}
                services={services}
                history={history}
                user={user}
              />
            )}
          </ServicesContext.Consumer>
        )}
      </UserContext.Consumer>
    </React.Fragment>
  );
}

export function getReleasePage(user: User): () => ReactElement {
  const subRoutes: AuthRouteConfiguration[] = [
    {
      path: "/release/:release_id",
      name: "Release details",
      exact: false,
      selected: true,
      main: ReleasePage,
    },
    {
      path: "/releases",
      name: "Back to releases",
      exact: false,
      main: ReleasePage,
    },
  ];

  return (): ReactElement =>
    Tabs(
      subRoutes.filter((route) => route.auth === undefined || route.auth(user))
    );
}
