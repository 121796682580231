import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import ErrorPanel from "../error";
import Loader from "../loader";
import {NamedItem} from "./select-named";
import MultiNamedSelect from "./multi-select-named";

export interface MultiDynamicSelectProps<T> {
  by?: string;
  load: () => Promise<T[]>;
  onSelect: (item: T[]) => void;
  onLoaded?: (items: T[]) => void;
  initialValue: Array<string | T>;
  disabled?: boolean;
}

interface MultiDynamicSelectState<T> {
  loading: boolean;
  error?: ApplicationError;
  items: T[];
  selectedItemsIds: string[];
}

/**
 * Generic component to display select controls where options are fetched
 * dynamically.
 */
export default class MultiDynamicSelect<T extends NamedItem> extends Component<
  MultiDynamicSelectProps<T>,
  MultiDynamicSelectState<T>
> {
  private select: React.RefObject<MultiNamedSelect<T>>;

  constructor(props: MultiDynamicSelectProps<T>) {
    super(props);

    this.select = React.createRef();
    this.state = {
      loading: true,
      items: [],
      selectedItemsIds: [],
    };
  }

  setSelected(ids: string[]): void {
    this.select.current?.setSelected(ids);
  }

  setInitialItems(items: T[]): T[] {
    if (items.length === 0) {
      return [];
    }

    // if the options contain a single element, select it automatically
    if (items.length === 1) {
      const singleItem = [items[0]];

      this.props.onSelect(singleItem);
      return singleItem;
    }

    const {initialValue} = this.props;
    if (!initialValue) {
      return [];
    }

    const initialItems = items.filter((item) => {
      return (
        initialValue.indexOf(item) > -1 || initialValue.indexOf(item.id) > -1
      );
    });

    this.props.onSelect(initialItems);
    return initialItems;
  }

  load(): void {
    this.setState({
      loading: true,
      error: undefined,
    });
    this.props.load().then(
      (items) => {
        if (this.props.onLoaded) {
          this.props.onLoaded(items);
        }

        const initialItems = this.setInitialItems(items);

        this.setState({
          items,
          loading: false,
          selectedItemsIds: initialItems
            ? initialItems.map((item) => item.id)
            : [],
        });
      },
      (error: ApplicationError) => {
        this.setState({
          loading: false,
          error,
        });
      }
    );
  }

  componentDidUpdate(props: MultiDynamicSelectProps<T>): void {
    if (props.by !== this.props.by) {
      this.load();
    }
  }

  componentDidMount(): void {
    this.load();
  }

  renderOptions(): ReactElement {
    const {disabled} = this.props;
    const {error, loading, items, selectedItemsIds} = this.state;

    if (error) {
      return <ErrorPanel error={error} />;
    }

    if (loading) {
      return <Loader className="mini" />;
    }

    return (
      <MultiNamedSelect
        items={items}
        onSelect={this.props.onSelect}
        initialValue={selectedItemsIds}
        disabled={disabled}
        ref={this.select}
      />
    );
  }

  render(): ReactElement {
    return (
      <React.Fragment>
        {this.renderOptions()}
        {this.props.children}
      </React.Fragment>
    );
  }
}
