import * as H from "history";
import Alert, {AlertSeverity} from "../../common/alert";
import Collapsible from "../../common/collapsible";
import EditRelease from "./edit-release";
import ErrorPanel from "../../common/error";
import Head from "../../common/head";
import Loader from "../../common/loader";
import Panel from "../../common/panel";
import PublishRelease from "./publish-release";
import React, {Component, ReactElement} from "react";
import ReleaseFiles from "./release-files-upload";
import ReleaseOrganizationsForm from "./release-organizations-form";
import ReleaseSummary from "./release-summary";
import {ApplicationError} from "../../../common/errors";
import {IServices} from "../../../service/services";
import {ReleaseDetails} from "../../../service/domain/releases";
import {User} from "../../../service/user";

export interface ReleasePageProps {
  id: string;
  services: IServices;
  history: H.History<H.LocationState>;
  user: User;
}

export interface ReleasePageState {
  loading: boolean;
  waiting: boolean;
  error?: ApplicationError;
  loadingError?: ApplicationError;
  details: ReleaseDetails | null;
}

export default class ReleasePage extends Component<
  ReleasePageProps,
  ReleasePageState
> {
  constructor(props: ReleasePageProps) {
    super(props);

    this.state = {
      loading: true,
      waiting: false,
      details: null,
    };
    this.load();
  }

  get id(): string {
    return this.props.id;
  }

  componentDidUpdate(props: ReleasePageProps): void {
    if (props.id !== this.id) {
      this.load();
    }
  }

  load(): void {
    const service = this.props.services.releases;

    if (!this.state.loading) {
      this.setState({
        loading: true,
        loadingError: undefined,
      });
    }

    service.getReleaseById(this.id).then(
      (data) => {
        this.setState({
          loading: false,
          details: data,
        });
      },
      (error: ApplicationError) => {
        this.setState({
          loading: false,
          loadingError: error,
        });
      }
    );
  }

  onUpdate(data: ReleaseDetails): void {
    this.setState({
      details: data,
    });
  }

  render(): ReactElement {
    const {id, history, services, user} = this.props;
    const {loading, error, details, waiting, loadingError} = this.state;

    return (
      <Panel loading={loading} error={loadingError}>
        {waiting && <Loader className="overlay" />}
        {details === null && (
          <Alert
            title="Release not found"
            message={`The Release with id ${id} was not found.`}
            severity={AlertSeverity.info}
          />
        )}
        {details !== null && (
          <div>
            <Head title={details.name} />
            {error && (
              <section>
                <ErrorPanel error={error} />
              </section>
            )}
            {details.draft && (
              <>
                <Collapsible title="Release base settings">
                  <EditRelease
                    details={details}
                    services={services}
                    onUpdate={(data) => this.onUpdate(data)}
                  />
                </Collapsible>
                <Collapsible title="Release files">
                  <ReleaseFiles
                    details={details}
                    services={services}
                    onUpdate={(data) => this.onUpdate(data)}
                  />
                </Collapsible>
                <Collapsible title="Release organizations">
                  <ReleaseOrganizationsForm
                    details={details}
                    services={services}
                    onUpdate={(data) => this.onUpdate(data)}
                  />
                </Collapsible>
                <Collapsible title="Publish release">
                  <PublishRelease
                    details={details}
                    services={services}
                    onUpdate={(data) => this.onUpdate(data)}
                  />
                </Collapsible>
              </>
            )}
            {details.draft === false && (
              <ReleaseSummary
                details={details}
                services={services}
                history={history}
                user={user}
              />
            )}
          </div>
        )}
      </Panel>
    );
  }
}
