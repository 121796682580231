import {delay} from "lodash";
import React, {Component, ReactElement} from "react";
import {Country, CountriesAPI} from "../../../service/domain/countries";
import MultiDynamicSelect from "../../common/forms/multi-select-named-dynamic";
import {NamedItem} from "../../common/forms/select-named";

export interface CountriesSelectProps {
  service: CountriesAPI;
  selected?: Array<Country | string>;
  onSelect?: (countries: Country[]) => void;
  readonly?: boolean;
}

export interface CountriesSelectState {
  error?: string;
  forceSelected?: Country[];
  disabled?: Array<Country | string>;
  countries: Country[];
}

export default class CountriesSelect extends Component<
  CountriesSelectProps,
  CountriesSelectState
> {
  private select: React.RefObject<MultiDynamicSelect<NamedItem>>;

  constructor(props: CountriesSelectProps) {
    super(props);

    this.select = React.createRef();
    this.state = {countries: []};
  }

  async loadCountries(): Promise<Country[]> {
    return this.props.service.getCountries();
  }

  public get value(): Country[] {
    return this.state.countries;
  }

  public setCountries(ids: string[]): void {
    this.select.current?.setSelected(ids);
  }

  public get ids(): string[] {
    const {countries} = this.state;
    return countries.map((item) => item.id);
  }

  getAllSelectedCountries(): Array<Country | string> {
    const {selected} = this.props;

    if (selected !== undefined) {
      return selected;
    }

    const forcedSelected = this.state.forceSelected;

    if (forcedSelected !== undefined) {
      return forcedSelected;
    }
    return [];
  }

  onSelect(countries: Country[]): void {
    this.setState({
      countries,
    });

    delay(() => {
      if (this.props.onSelect) this.props.onSelect(countries);
    }, 50);
  }

  render(): ReactElement {
    const {selected} = this.props;
    const {error} = this.state;

    return (
      <React.Fragment>
        <MultiDynamicSelect
          load={() => this.loadCountries()}
          initialValue={selected || []}
          onSelect={(countries) => this.onSelect(countries)}
          ref={this.select}
        />
        {error && <p className="ui-error">{error}</p>}
      </React.Fragment>
    );
  }
}
