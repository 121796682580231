import {Button} from "@material-ui/core";
import React, {Component, ReactElement} from "react";

export interface ConfirmButtonsProps {
  cancel: () => void;
  confirm: () => void;
  confirmDisabled?: boolean;
}

export default class ConfirmButtons extends Component<ConfirmButtonsProps> {
  render(): ReactElement {
    const {confirmDisabled} = this.props;
    return (
      <div className="form-buttons">
        <Button
          onClick={() => this.props.cancel()}
          autoFocus
          className="cancel-button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="confirm-button"
          color="secondary"
          onClick={() => this.props.confirm()}
          disabled={confirmDisabled}
        >
          Confirm
        </Button>
      </div>
    );
  }
}
