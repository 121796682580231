import Form from "../../common/forms/form";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import {dismissDialog} from "../view-functions";
import {IServices} from "../../../service/services";
import {ReleaseDetails} from "../../../service/domain/releases";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";

export interface PublishReleaseProps {
  services: IServices;
  details: ReleaseDetails;
  onUpdate: (updated: ReleaseDetails) => void;
}

export interface PublishReleaseState {
  loading: boolean;
  error?: ApplicationError;
  confirm: ConfirmDialogProps;
  sendEmailNotifications: boolean;
}

export default class PublishRelease extends Component<
  PublishReleaseProps,
  PublishReleaseState
> {
  constructor(props: PublishReleaseProps) {
    super(props);

    this.state = {
      loading: false,
      confirm: closedDialog(),
      sendEmailNotifications: true,
    };
  }

  confirm(): void {
    if (this.state.loading) return;

    const {details} = this.props;
    const {sendEmailNotifications} = this.state;

    this.setState({
      error: undefined,
      loading: true,
    });

    this.props.services.releases
      .publishRelease({
        id: details.id,
        eTag: details.eTag,
        sendEmailNotifications,
      })
      .then(
        (data) => {
          this.setState({
            error: undefined,
            loading: false,
          });

          this.props.onUpdate(data);
        },
        (error: ApplicationError) => {
          error.retry = () => {
            this.confirm();
          };
          this.setState({
            error,
            loading: false,
          });
        }
      );
  }

  canBePublished(): boolean {
    const {details} = this.props;
    if (!details || !details.countries.length || !details.nodes.length)
      return false;
    return true;
  }

  onPublishClick(): void {
    this.setState({
      confirm: {
        open: true,
        title: "Publish the release?",
        description:
          "If confirmed, the release is published and cannot " +
          "be modified anymore.",
        close: () => {
          dismissDialog(this);
        },
        confirm: () => {
          this.confirm();
        },
      },
    });
  }

  toggleSendEmailNotifications(): void {
    const {sendEmailNotifications} = this.state;

    this.setState({
      sendEmailNotifications: !sendEmailNotifications,
    });
  }

  render(): ReactElement {
    const {loading, error, confirm, sendEmailNotifications} = this.state;
    const canBePublished = this.canBePublished();

    return (
      <Form waiting={loading} error={error}>
        {canBePublished && (
          <div className="publish-region">
            <Button color="primary" onClick={() => this.onPublishClick()}>
              Publish Release
            </Button>
          </div>
        )}
        {canBePublished === false && (
          <p>
            <em>
              This release cannot be published, yet. It must be configured at
              least for one country and with one file.
            </em>
          </p>
        )}
        <ConfirmDialog {...confirm}>
          <div className="publish-options-region">
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmailNotifications}
                  onClick={() => this.toggleSendEmailNotifications()}
                  title={
                    "If enabled, all users having access to this release " +
                    "will receive an email notification, to inform them " +
                    "that a new release is available."
                  }
                  color="primary"
                />
              }
              label="Send email notifications."
            />
          </div>
        </ConfirmDialog>
      </Form>
    );
  }
}
