import Head from "../common/head";
import React from "react";

export default function Home(): React.ReactElement {
  return (
    <div className="wrapper">
      <Head title="Home" />
      <section>
        <h1>Welcome to the Oticon Medical Software Center.</h1>
        <p>
          In this portal, HCPs can download the releases of fitting software,
          and administrators can configure the releases.
        </p>
      </section>
    </div>
  );
}
