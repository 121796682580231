import React, {Component, ReactElement} from "react";
import {IServices} from "../../../service/services";
import LoadingView from "../../common/loading-view";
import AppInfo from "./app-info";

export interface InfoBarProps {
  services: IServices;
}

export default class InfoBar extends Component<InfoBarProps> {
  render(): ReactElement {
    const {services} = this.props;
    return (
      <div className="fixed-data">
        <LoadingView
          mini
          provider={services.info.getAppInfo}
          then={(appInfo) => {
            return (
              <>
                <AppInfo {...appInfo} />
              </>
            );
          }}
        />
      </div>
    );
  }
}
