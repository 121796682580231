import Alert, {AlertSeverity} from "../../common/alert";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {ApplicationError, InterfaceError} from "../../../common/errors";
import {Button, Grid} from "@material-ui/core";
import {ConfigurationProblem} from "./common";
import EmailInput from "../emails/email-input";
import {IServices} from "../../../service/services";
import {HCPRole} from "../../../service/domain/employees";
import {HearingCareProfessionalContext} from "../../../service/domain/hcps";
import HCPOrganizationSelect from "../hcps/hcp-organization-select";
import ServiceSettings from "../../../service/settings"; // TODO: improve
import {NamedItem} from "../../common/forms/select-named";

interface Summary {
  element: React.ReactElement;
}

export interface NewInvitationFormProps {
  services: IServices;
  context: HearingCareProfessionalContext;
}

export interface NewInvitationFormState {
  emails: string[];
  waiting: boolean;
  done: boolean;
  error?: ApplicationError;
  problem?: ConfigurationProblem;
  confirmButtonDisabled: boolean;
  selectedOrganization: NamedItem;

  summary?: Summary;
}

interface InvitationResult {
  success: boolean;
  error: ApplicationError | null;
  invitationId: string;
  email: string;
}

export default class NewInvitationForm extends Component<
  NewInvitationFormProps,
  NewInvitationFormState
> {
  private submitting: boolean;
  private emailInput: React.RefObject<EmailInput>;

  constructor(props: NewInvitationFormProps) {
    super(props);

    this.submitting = false;
    this.state = this.initialState();
    this.emailInput = React.createRef();
  }

  initialState(): NewInvitationFormState {
    return {
      done: false,
      waiting: false,
      emails: [],
      confirmButtonDisabled: true,
      selectedOrganization: {id: "", name: ""},
    };
  }

  validate(): boolean {
    const validEmails = this.emailInput.current?.validateEmails();

    if (validEmails !== true) {
      return false;
    }

    return this.enableConfirmButton();
  }

  onConfirmClick(): void {
    if (this.submitting) {
      return;
    }
    if (this.state.confirmButtonDisabled) {
      return;
    }
    if (!this.validate()) {
      return;
    }

    this.submitting = true;

    const {emails} = this.state;

    if (!emails) {
      throw new InterfaceError("Missing emails");
    }

    this.setState({waiting: true});

    const operations: Array<Promise<InvitationResult>> = emails.map(
      (email) => {
        return this.sendInvitation(email);
      }
    );

    Promise.all(operations).then(
      (results) => {
        this.submitting = false;
        this.handleResults(results);
      },
      () => {
        this.submitting = false;
        this.setState({waiting: false});
      }
    );
  }

  tryAgain(): void {
    this.setState({
      summary: undefined,
    });
  }

  handleResults(results: InvitationResult[]): void {
    const failedOperations = results.filter((item) => item.success === false);
    const succeededOperations = results.filter(
      (item) => item.success === true
    );

    let summary: Summary;

    if (failedOperations.length === results.length) {
      const error = new ApplicationError("All operations failed", 500);
      error.retry = () => this.tryAgain();

      summary = {
        element: (
          <div>
            <h2>Oh no!</h2>
            <ErrorPanel
              error={{
                error: error,
              }}
            />
            <br />
            <Button onClick={() => this.tryAgain()}>Try again</Button>
          </div>
        ),
      };
    } else if (failedOperations.length > 0 && succeededOperations.length > 0) {
      const error = new ApplicationError("Some operations failed", 500);
      summary = {
        element: (
          <div>
            <h2>Oh no! The invitations to the following recipients failed:</h2>
            <ul>
              {failedOperations.map((item) => {
                return <li key={item.email}>{item.email}</li>;
              })}
            </ul>
            <ErrorPanel
              error={{
                error: error,
              }}
            />
            <br />
            <Button onClick={() => this.tryAgain()}>Try again</Button>
          </div>
        ),
      };
    } else {
      const {emails} = this.state;
      summary = {
        element: (
          <Grid item xs={12}>
            <h2>Invitation sent!</h2>
            <div className="summary-text">
              <p>
                An invitation was successfully sent to:&nbsp;
                <strong>{emails.join("; ")}</strong>
              </p>
              <p>The invitation(s) can be found among sent invitations.</p>
            </div>
            <Button onClick={() => this.dismissSummary()}>
              Send a new invitation
            </Button>
          </Grid>
        ),
      };
    }
    this.setState({waiting: false, summary});
  }

  async sendInvitation(email: string): Promise<InvitationResult> {
    const {invitations} = this.props.services;
    const {selectedOrganization} = this.state;

    try {
      const data = await invitations.sendInvitation({
        email: email,
        role: HCPRole.hcp,
        organizationId: selectedOrganization.id,
        cultureCode: "en-us",
        brand: ServiceSettings.oticonMedicalId,
        redirectUrl: ServiceSettings.invitationRedirectURL,
        clientId: ServiceSettings.registrationClientId,
        tenant: ServiceSettings.tenant,
        roleClaimName: "kisei_role",
        roleClaimValue: "",
      });

      return {
        email,
        success: true,
        error: null,
        invitationId: data.id,
      };
    } catch (error) {
      // Note: we intentionally "eat" the exception because Promise.all
      // need to try for each operation - they are independent.
      // Otherwise Promise.all would stop at the first failure.
      return {
        email,
        success: false,
        error,
        invitationId: "",
      };
    }
  }

  handleError(error: ApplicationError): void {
    if (error.status === 400) {
      const details = error.data;

      if (details) {
        // we can display relevant information to the user.
        this.setState({
          waiting: false,
          problem: {
            title: "Cannot complete the operation",
            message: typeof details === "string" ? details : details.error,
            severity: AlertSeverity.warning,
          },
        });
        return;
      }
    }
    this.setState({
      waiting: false,
      error,
    });
  }

  enableConfirmButton(emails?: string[]): boolean {
    if (!emails) {
      emails = this.state.emails;
    }
    if (emails && emails.length > 0) {
      return true;
    }

    return false;
  }

  onEmailsChange(emails: string[]): void {
    this.setState({
      emails,
      confirmButtonDisabled: !this.enableConfirmButton(emails),
    });
  }

  dismissSummary(): void {
    const emailInput = this.emailInput.current;
    if (emailInput) {
      emailInput.value = "";
    }
    this.setState({
      emails: [],
      summary: undefined,
    });
  }

  onOrganizationSelect(organization: NamedItem): void {
    this.setState({
      selectedOrganization: organization,
    });
  }

  render(): ReactElement {
    const {context} = this.props;
    const {
      waiting,
      error,
      confirmButtonDisabled,
      problem,
      summary,
    } = this.state;

    return (
      <div className={summary ? "summary" : "edit"}>
        <div className="summary-view">{summary && summary.element}</div>
        <div className="edit-view">
          {waiting && <Loader className="overlay" />}
          <div className="presentation">
            <h1>New invitation</h1>
            <p>
              Send a new invitation to invite one or more HCPs to join your
              organization. When the invited persons accept their invitation,
              they gain access to the software download platform.
            </p>
            <dl>
              <dt>Organization</dt>
              <dd>
                <HCPOrganizationSelect
                  context={context}
                  onOrganizationSelect={this.onOrganizationSelect.bind(this)}
                ></HCPOrganizationSelect>
              </dd>
            </dl>
          </div>
          <dl>
            <dt>Email address(es)</dt>
            <dd>
              <EmailInput
                onChange={this.onEmailsChange.bind(this)}
                ref={this.emailInput}
              />
            </dd>
          </dl>

          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <ErrorPanel error={error} />
              </Grid>
            )}
            {problem && (
              <Grid item xs={12}>
                <Alert
                  title={problem.title}
                  message={problem.message}
                  severity={problem.severity}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <div
                className={
                  "form-buttons " +
                  (confirmButtonDisabled ? "ui-disabled" : "ui-enabled")
                }
              >
                <Button
                  onClick={() => this.onConfirmClick()}
                  className="confirm-button"
                  color="secondary"
                >
                  Confirm
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
