import React, {Component, ReactElement} from "react";
import formatDate from "../../../common/format-date";
import {ReleaseHistory} from "../../../service/domain/releases";
import {ItemProperty, StaticTable} from "../../common/tables/tables";

export interface ReleaseHistoryTableProps {
  history: ReleaseHistory[];
}

function getProperties(): ItemProperty<ReleaseHistory>[] {
  return [
    {
      id: "user",
      label: "User",
      notSortable: true,
      render: (item: ReleaseHistory) => {
        return item.user;
      },
    },
    {
      id: "description",
      label: "Description",
      notSortable: true,
      render: (item: ReleaseHistory) => {
        return item.description;
      },
    },
    {
      id: "creationTime",
      label: "At",
      notSortable: true,
      render: (item: ReleaseHistory) => {
        return formatDate(item.timeStamp);
      },
    },
  ];
}

// eslint-disable-next-line max-len
export default class ReleaseHistoryTable extends Component<ReleaseHistoryTableProps> {
  render(): ReactElement {
    const {history} = this.props;

    history.sort((a, b) => {
      const aDate = new Date(a.timeStamp);
      const bDate = new Date(b.timeStamp);

      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });

    return (
      <StaticTable<ReleaseHistory>
        properties={getProperties()}
        actions={[]}
        items={history}
      />
    );
  }
}
