import debounce from "lodash/debounce";
import ErrorPanel from "../error";
import Loader from "../loader";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {Link} from "react-router-dom";
import {PaginationBar} from "../pagination";
import {SortOrder, swapSortOrder} from ".";
import {
  TableCell,
  Checkbox,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableContainer,
  Button,
  TableSortLabel,
} from "@material-ui/core";
import {PaginatedSet} from "../../../service/domain/lists";
import {delay} from "lodash";
import {exportToExcel} from "../../../common/xlsx";

export interface ItemProperty<T> {
  id: string;
  label: string;
  hidden?: true;
  notSortable?: true;
  render: (item: T) => ReactElement | string | null | undefined;
}

interface TableHeadersProps<T> {
  orderBy: string;
  sortOrder: SortOrder;
  items: ItemProperty<T>[];
  onSort: (item: ItemProperty<T>) => void;
}

class TableHeaders<T> extends Component<TableHeadersProps<T>> {
  render(): ReactElement {
    const {items, orderBy, sortOrder, onSort} = this.props;

    const cells = items
      .filter((item) => !item.hidden)
      .map((item) => (
        <TableCell
          key={item.id}
          className={item.id}
          sortDirection={orderBy === item.id ? sortOrder : false}
        >
          {item.notSortable ? (
            item.label
          ) : (
            <TableSortLabel
              active={orderBy === item.id}
              direction={orderBy === item.id ? sortOrder : "asc"}
              onClick={() => onSort(item)}
            >
              {orderBy === item.id ? (
                <span
                  title={
                    sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"
                  }
                >
                  {item.label}
                </span>
              ) : (
                item.label
              )}
            </TableSortLabel>
          )}
        </TableCell>
      ));

    return <React.Fragment>{cells}</React.Fragment>;
  }
}

export interface ItemAction<T> {
  icon: ReactElement;
  title: string;
  onClick: (item: T) => void;
  condition?: (item: T) => boolean;
  disabled?: (item: T) => boolean;
}

export enum ButtonColor {
  primary = "primary",
  secondary = "secondary",
  default = "default",
}

export interface TableButton<T> {
  label: string;
  onClick: (selectedItems: T[]) => void;
  disabled?: boolean;
  color?: ButtonColor;
}

export interface StaticTableProps<T> {
  selectable?: boolean;
  items: T[];
  actions?: ItemAction<T>[];
  buttons?: TableButton<T>[];
  properties: ItemProperty<T>[];
  defaultSortProperty?: string;
  defaultSortOrder?: SortOrder;
  onSort?: (property: string, order: SortOrder) => void;
  onItemClick?: (item: T) => void;
  onSelect?: (items: T[]) => void;
  selectOnClick?: boolean;
  selectMultiOnClick?: boolean;
  detailsRoute?: string;
  noItemsElement?: ReactElement;
  selectedItems?: T[];
}

export interface StaticTableState<T> {
  sortProperty: string;
  sortOrder: SortOrder;
  selectedItems: T[];
  dense: boolean;
}

export interface TableItem {
  id: string;
}

export class StaticTable<T extends TableItem> extends Component<
  StaticTableProps<T>,
  StaticTableState<T>
> {
  private root: React.RefObject<HTMLDivElement>;

  constructor(props: StaticTableProps<T>) {
    super(props);

    this.state = {
      sortProperty: props.defaultSortProperty || "",
      sortOrder: props.defaultSortOrder || "desc",
      selectedItems: props.selectedItems || [],
      dense: false,
    };
    this.root = React.createRef();
  }

  onItemClick(item: T): void {
    const {onItemClick, selectOnClick, selectMultiOnClick} = this.props;

    if (onItemClick) {
      onItemClick(item);
    }

    if (selectOnClick) {
      if (selectMultiOnClick) {
        // enable selecting many items clicking on them
        this.toggleItemSelection(item);
      } else {
        // select only the last clicked item
        this.setState({
          selectedItems: [item],
        });
      }

      this.onSelect();
    }
  }

  onSelect(): void {
    delay(() => {
      if (this.props.onSelect) {
        this.props.onSelect(this.state.selectedItems);
      }
    }, 10);
  }

  componentDidUpdate(props: StaticTableProps<T>): void {
    if (props.items !== this.props.items) {
      const {selectedItems} = this.state;

      if (selectedItems.length) {
        const matchingItems = this.props.items.filter((item) =>
          selectedItems.find((selectedItem) => selectedItem.id === item.id)
        );
        this.setState({
          selectedItems: matchingItems,
        });
      }
    }
  }

  onSort(item: ItemProperty<T>): void {
    const newSortOrder = swapSortOrder(this.state.sortOrder);
    this.setState({
      sortOrder: newSortOrder,
      sortProperty: item.id,
    });

    if (this.props.onSort) this.props.onSort(item.id, newSortOrder);
  }

  onButtonClick(button: TableButton<T>): void {
    button.onClick(this.state.selectedItems);
  }

  toggleItemSelection(item: T): void {
    const selected = this.state.selectedItems;

    if (selected.indexOf(item) > -1) {
      selected.splice(selected.indexOf(item), 1);
    } else {
      selected.push(item);
    }

    this.setState({
      selectedItems: [...selected],
    });

    this.onSelect();
  }

  toggleItemsSelection(): void {
    const {selectOnClick, selectMultiOnClick} = this.props;
    let selected = this.state.selectedItems;

    if (selected.length) {
      selected = [];
    } else {
      if (!selectOnClick || (selectOnClick && selectMultiOnClick)) {
        selected = [...this.props.items];
      }
    }

    this.setState({
      selectedItems: [...selected],
    });

    this.onSelect();
  }

  private getAllChecked(): boolean {
    const {selectedItems} = this.state;
    const {items} = this.props;
    if (!items.length) return false;
    return items.every((item) => selectedItems.indexOf(item) > -1);
  }

  private getSomeChecked(): boolean {
    const {selectedItems} = this.state;
    const {items} = this.props;
    if (!items.length) return false;
    return (
      !items.every((item) => selectedItems.indexOf(item) > -1) &&
      !!selectedItems.length
    );
  }

  isItemSelected(item: T): boolean {
    return this.state.selectedItems.indexOf(item) > -1;
  }

  render(): ReactElement {
    const {
      children,
      selectable,
      properties,
      items,
      actions,
      buttons,
      detailsRoute,
      noItemsElement,
    } = this.props;
    const {dense, sortProperty, sortOrder} = this.state;
    const hasActions = !!(actions && actions.length);

    return (
      <div className="table-region" ref={this.root}>
        {items.length ? (
          <TableContainer>
            <Table size={dense ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  {selectable && (
                    <TableCell>
                      <Checkbox
                        checked={this.getAllChecked()}
                        indeterminate={this.getSomeChecked()}
                        onClick={() => this.toggleItemsSelection()}
                      />
                    </TableCell>
                  )}
                  {detailsRoute && <TableCell></TableCell>}
                  <TableHeaders
                    items={properties}
                    orderBy={sortProperty}
                    sortOrder={sortOrder}
                    onSort={this.onSort.bind(this)}
                  />
                  {hasActions && (
                    <TableCell className="actions">Actions</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => {
                  return (
                    <TableRow
                      key={item.id}
                      selected={this.isItemSelected(item)}
                      onClick={() => this.onItemClick(item)}
                    >
                      {selectable && (
                        <TableCell>
                          <Checkbox
                            checked={this.isItemSelected(item)}
                            onClick={() => this.toggleItemSelection(item)}
                          />
                        </TableCell>
                      )}
                      {detailsRoute && (
                        <TableCell>
                          <Link
                            to={detailsRoute + item.id}
                            title="Go to details"
                          >
                            <i className="fas fa-info-circle details-link"></i>
                          </Link>
                        </TableCell>
                      )}
                      {properties
                        .filter((item) => !item.hidden)
                        .map((property) => {
                          return (
                            <TableCell
                              key={property.id}
                              className={property.id}
                            >
                              {property.render(item)}
                            </TableCell>
                          );
                        })}
                      {hasActions && (
                        <TableCell className="actions">
                          {actions
                            ?.filter((action) => {
                              return (
                                !action.condition || action.condition(item)
                              );
                            })
                            .map((action) => {
                              return (
                                <Button
                                  key={action.title}
                                  onClick={() => action.onClick(item)}
                                  disabled={
                                    action.disabled
                                      ? action.disabled(item)
                                      : false
                                  }
                                  title={action.title}
                                >
                                  {action.icon}
                                </Button>
                              );
                            })}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          noItemsElement
        )}
        {children && <div className="table-children">{children}</div>}
        {buttons && !!buttons.length && (
          <div className="table-buttons buttons-area">
            {buttons.map((button) => {
              return (
                <Button
                  key={button.label}
                  onClick={() => this.onButtonClick(button)}
                  disabled={button.disabled}
                  color={button.color}
                >
                  {button.label}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export interface DynamicTableProps<T, FiltersType>
  extends StaticTableProps<T> {
  provider: (
    page: number,
    sortBy: string,
    filters: FiltersType
  ) => Promise<PaginatedSet<T>>;
  filters: FiltersType;
  onDataFetched?: (data: PaginatedSet<T>) => void;
  paginationExtraButtons?: ReactElement;
  exportFileName?: string;
  exportTransform?: (items: T) => any;
}

export interface DynamicTableState<T> extends StaticTableState<T> {
  items: T[];
  loading: boolean;
  error?: ApplicationError;
  total: number;
  page: number;
}

export class DynamicTable<T extends TableItem, FiltersType> extends Component<
  DynamicTableProps<T, FiltersType>,
  DynamicTableState<T>
> {
  constructor(props: DynamicTableProps<T, FiltersType>) {
    super(props);

    // TODO: support storing and restoring page number and sorting in memory
    // and in query, depending on the current path
    this.state = {
      dense: false,
      items: props.items,
      sortProperty: props.defaultSortProperty || "",
      sortOrder: props.defaultSortOrder || "desc",
      selectedItems: [],
      loading: false,
      total: 0,
      page: 1,
    };
  }

  refresh(): void {
    this.setState({
      items: [...this.state.items],
    });
  }

  componentDidUpdate(props: DynamicTableProps<T, FiltersType>): void {
    if (props.filters !== this.props.filters) {
      this.applyFilters();
    }
  }

  componentDidMount(): void {
    this.load();
  }

  applyFilters = debounce(() => {
    this.load();
  }, 300);

  onSort(property: string, order: SortOrder): void {
    this.setState({
      sortOrder: order,
      sortProperty: property,
    });
    if (this.props.onSort) this.props.onSort(property, order);
    this.applyFilters();
  }

  onDataFetched(data: PaginatedSet<T>): void {
    if (this.props.onDataFetched) {
      this.props.onDataFetched(data);
    }
  }

  load(): void {
    this.setState({
      loading: true,
      error: undefined,
    });

    const {filters} = this.props;
    const {page, sortProperty, sortOrder} = this.state;

    this.props.provider(page, `${sortProperty} ${sortOrder}`, filters).then(
      (data: PaginatedSet<T>) => {
        this.onDataFetched(data);

        this.setState({
          items: data.items,
          total: data.total,
          error: undefined,
          loading: false,
        });
      },
      (error: ApplicationError) => {
        error.retry = () => {
          this.load();
        };
        this.setState({
          error,
          loading: false,
        });
      }
    );
  }

  async loadAllDocuments(): Promise<T[]> {
    if (this.state.loading) {
      return [];
    }

    this.setState({
      loading: true,
      error: undefined,
    });

    let total = -1;
    let items: T[] = [];
    let page = 0;
    const {filters} = this.props;
    const {sortProperty, sortOrder} = this.state;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      page += 1;

      try {
        const pagedItems = await this.props.provider(
          page,
          `${sortProperty} ${sortOrder}`,
          filters
        );

        if (total === -1) {
          total = pagedItems.total;
        }

        items = items.concat(pagedItems.items);

        if (pagedItems.items.length === 0 || items.length >= total) {
          break;
        }
      } catch (error: any) {
        this.setState({
          loading: false,
          error,
        });
        return [];
      }
    }

    this.setState({
      loading: false,
    });

    return items;
  }

  onPageChange(page: number): void {
    this.setState({page});

    setTimeout(() => {
      this.load();
    }, 0);
  }

  getItemsForExport(items: T[]): any[] {
    const {exportTransform} = this.props;

    if (exportTransform) {
      return items.map(exportTransform);
    }

    return items;
  }

  onExportClick(): void {
    exportToExcel(
      this.getItemsForExport(this.state.items),
      this.props.exportFileName
    );
  }

  async onExportAllClick(): Promise<void> {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const allItems = await this.loadAllDocuments();

    if (allItems.length) {
      exportToExcel(
        this.getItemsForExport(allItems),
        this.props.exportFileName
      );
    }
  }

  render(): ReactElement {
    const props = this.props;
    const {items, page, total, loading, error} = this.state;

    return (
      <div className="dynamic-table-region">
        {loading && <Loader className={items.length ? "covering" : ""} />}
        {error !== undefined && <ErrorPanel error={error} />}
        {items.length > 0 && (
          <div className="table-pagination">
            <PaginationBar
              page={page}
              itemsCount={total}
              onPageChange={this.onPageChange.bind(this)}
            >
              {props.paginationExtraButtons}
              <div className="buttons">
                <Button
                  onClick={this.onExportClick.bind(this)}
                  title="Export visible to Excel"
                >
                  <i className="fas fa-file-excel"></i>
                </Button>
                <Button
                  onClick={this.onExportAllClick.bind(this)}
                  title="Export all to Excel (slow operation)"
                >
                  <i className="fas fa-globe-europe"></i>
                </Button>
              </div>
            </PaginationBar>
          </div>
        )}
        {error === undefined && (
          <StaticTable<T>
            {...props}
            items={items}
            onSort={this.onSort.bind(this)}
            noItemsElement={
              loading ? undefined : <i className="no-results">No results.</i>
            }
          />
        )}
        {items.length > 0 && (
          <div className="table-pagination">
            <PaginationBar
              page={page}
              itemsCount={total}
              onPageChange={this.onPageChange.bind(this)}
            >
              {props.paginationExtraButtons}
            </PaginationBar>
          </div>
        )}
      </div>
    );
  }
}
