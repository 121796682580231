import {uniqueId} from "lodash";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {IServices} from "../../../service/services";
import ConfirmButtons from "../../common/forms/confirm-buttons";
import EmailInput from "../../common/forms/email-input";
import ServiceSettings from "../../../service/settings";
import Form from "../../common/forms/form";

interface ForgottenPasswordFormProps {
  onCancel: () => void;
  onDone: () => void;
  services: IServices;
}

interface ForgottenPasswordFormState {
  waiting: boolean;
  value: string | null;
  error: ApplicationError | null;
}

export default class ForgottenPasswordForm extends Component<
  ForgottenPasswordFormProps,
  ForgottenPasswordFormState
> {
  private submitting: boolean;
  private fieldId: string;

  constructor(props: ForgottenPasswordFormProps) {
    super(props);

    this.submitting = false;
    this.fieldId = uniqueId("email");

    this.state = {
      waiting: false,
      error: null,
      value: null,
    };
  }

  cancel(): void {
    this.props.onCancel();
  }

  confirm(): void {
    const {value} = this.state;

    if (value === null) {
      return;
    }

    if (this.submitting) return;
    this.submitting = true;

    this.setState({
      waiting: true,
    });

    const {services} = this.props;

    services.account
      .initPasswordReset({
        email: value,
        tenant: ServiceSettings.tenant,
        redirectUrl: ServiceSettings.passwordResetRedirectURL,
        clientId: ServiceSettings.registrationClientId,
        cultureCode: "en",
      })
      .then(
        () => {
          this.setState({
            waiting: false,
            error: null,
          });

          this.submitting = false;

          this.props.onDone();
        },
        (error: ApplicationError) => {
          if (
            error.data &&
            (error.data as any).code === "UserNotExistsInTenant"
          ) {
            // user not found
            // TODO: how to handle this? Should user enumeration be avoided?
            error = new ApplicationError(
              "The given email address is not recognized",
              1
            );
            error.title = "User not found";
          }

          this.setState({
            waiting: false,
            error,
          });

          this.submitting = false;
        }
      );
  }

  onChange(value: string[]): void {
    this.setState({
      value: value[0] || null,
    });
  }

  componentDidMount(): void {
    document.getElementById(this.fieldId)?.focus();
  }

  render(): ReactElement {
    const {waiting, error, value} = this.state;

    return (
      <>
        <Form waiting={waiting} error={error} onSubmit={() => this.confirm()}>
          <EmailInput
            id={this.fieldId}
            onChange={(value) => this.onChange(value)}
            placeholder="Type here your email address…"
          />
          <h3>Instructions</h3>
          <ol>
            <li>Type the email address of your HCP account in the field.</li>
            <li>Click the confirm button to start the password reset flow.</li>
            <li>
              Check your email inbox: when you receive the password reset
              email, click the link in it.
            </li>
            <li>
              Follow the instructions on the password reset page to change your
              password.
            </li>
          </ol>
          <ConfirmButtons
            cancel={() => this.cancel()}
            confirm={() => this.confirm()}
            confirmDisabled={value === null}
          />
        </Form>
      </>
    );
  }
}
