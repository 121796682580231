import OrganizationsTable from "./organizations-table";
import React, {Component, ReactElement} from "react";
import {IServices} from "../../../service/services";
import {OrganizationTableItem} from "../../../service/domain/organizations";
import {UserContext} from "../user-context";
import {ApplicationError} from "../../../common/errors";
import ErrorPanel from "../../common/error";
import ConfirmButtons from "../../common/forms/confirm-buttons";
import {ReleaseDetails} from "../../../service/domain/releases";

export interface ReleaseOrganizationsSelectProps {
  details: ReleaseDetails;
  services: IServices;
  onCancel: () => void;
  onUpdate: (release: ReleaseDetails) => void;
}

export interface ReleaseOrganizationsSelectState {
  selectedItems: OrganizationTableItem[];
  error?: ApplicationError;
}

export default class ReleaseOrganizationsSelect extends Component<
  ReleaseOrganizationsSelectProps,
  ReleaseOrganizationsSelectState
> {
  constructor(props: ReleaseOrganizationsSelectProps) {
    super(props);

    this.state = {
      selectedItems: [],
    };
  }

  cancel(): void {
    this.props.onCancel();
  }

  confirm(): void {
    const {details, services} = this.props;
    const {selectedItems} = this.state;

    if (selectedItems.length === 0) {
      return;
    }

    services.releases
      .bindReleaseToOrganizations(details.id, {
        items: selectedItems.map((item) => {
          return {id: item.id, name: item.name};
        }),
      })
      .then(
        (release) => {
          // success
          this.setState({
            error: undefined,
          });

          this.props.onUpdate(release);
        },
        (error: ApplicationError) => {
          this.setState({
            error,
          });
        }
      );
  }

  render(): ReactElement {
    const {services} = this.props;
    const {error} = this.state;

    return (
      <div className="filters-region">
        {error && <ErrorPanel error={error} />}
        <ConfirmButtons
          cancel={() => this.cancel()}
          confirm={() => this.confirm()}
        />
        <UserContext.Consumer>
          {(user) => (
            <OrganizationsTable
              user={user}
              services={services}
              onItemsSelected={(items) => {
                this.setState({selectedItems: items});
              }}
              selectable={true}
            />
          )}
        </UserContext.Consumer>
      </div>
    );
  }
}
