import Releases from "./releases/releases-table";
import Head from "../common/head";
import NewReleaseForm from "./releases/new-release";
import React, {ReactElement} from "react";
import {AuthRouteConfiguration} from "./routing";
import {i} from "../../locale";
import {ServicesContext} from "./services-context";
import {Tabs} from "../common/tabs";
import {User} from "../../service/user";
import {useHistory} from "react-router-dom";
import {UserContext} from "./user-context";

function ReleasesList(): ReactElement {
  return (
    <React.Fragment>
      <Head title={i().Pages.Releases} />
      <UserContext.Consumer>
        {(user) => (
          <ServicesContext.Consumer>
            {(services) => <Releases services={services} user={user} />}
          </ServicesContext.Consumer>
        )}
      </UserContext.Consumer>
    </React.Fragment>
  );
}

function NewReleasePage(): ReactElement {
  const history = useHistory();
  return (
    <React.Fragment>
      <Head title={i().Pages.NewRelease} />
      <ServicesContext.Consumer>
        {(services) => (
          <NewReleaseForm history={history} services={services} />
        )}
      </ServicesContext.Consumer>
    </React.Fragment>
  );
}

export function getReleasesPage(user: User): () => ReactElement {
  const subRoutes: AuthRouteConfiguration[] = [
    {
      path: "/releases",
      name: i().Pages.Releases,
      exact: true,
      main: ReleasesList,
    },
    {
      path: "/releases/new",
      name: i().Pages.NewRelease,
      exact: true,
      main: NewReleasePage,
      auth: (user: User) => {
        return user.isOMHQ();
      },
    },
  ];

  return (): ReactElement =>
    Tabs(
      subRoutes.filter((route) => route.auth === undefined || route.auth(user))
    );
}
