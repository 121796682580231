import HCPsTable from "./hcps/hcps-table";
import Head from "../common/head";
import React, {ReactElement} from "react";
import {AuthRouteConfiguration} from "./routing";
import {i} from "../../locale";
import {ServicesContext} from "./services-context";
import {Tabs} from "../common/tabs";
import {User} from "../../service/user";
import {HCPContext} from "./account/hcp-context";

function HCPsList(): ReactElement {
  return (
    <React.Fragment>
      <Head title={i().Pages.HCPs} />
      <HCPContext.Consumer>
        {(context) =>
          context !== null && (
            <ServicesContext.Consumer>
              {(services) => (
                <HCPsTable services={services} context={context} />
              )}
            </ServicesContext.Consumer>
          )
        }
      </HCPContext.Consumer>
    </React.Fragment>
  );
}

export function getHCPsPage(user: User): () => ReactElement {
  const subRoutes: AuthRouteConfiguration[] = [
    {
      path: "/hearing-care-professionals",
      name: "HCPs",
      exact: true,
      main: HCPsList,
    },
  ];

  return (): ReactElement =>
    Tabs(
      subRoutes.filter((route) => route.auth === undefined || route.auth(user))
    );
}
