import React, {Component, ReactElement} from "react";
import {isEnabledForApp, User} from "../../service/user";
import UnsupportedBrowserCourtesyView from "../common/unsupported-browser";
import {HCPContextView} from "./account/hcp-context";
import {hasStoredToken, userFromStorage, loginSilent} from "./auth";
import Login from "./login";
import UserNotSystemEnabled from "./not-system-enabled";
import {ServicesContext} from "./services-context";

export const UserContext = React.createContext<User>(new User());

interface UserContextState {
  user: User | null;
  waiting: boolean;
}

export class UserContextView extends Component<unknown, UserContextState> {
  constructor(props: unknown) {
    super(props);

    const user = userFromStorage();

    if (user !== null && user.isExpired()) {
      // try to obtain a token silently, if there is the possibility
      if (hasStoredToken()) {
        this.trySilentLogin(user);
      }

      this.state = {
        user: null,
        waiting: true,
      };
      return;
    }

    this.state = {
      user,
      waiting: false,
    };
  }

  trySilentLogin(user: User): void {
    let hint = user.options?.preferred_username;

    if (hint === undefined) {
      const emails = user.options?.emails;
      hint = emails ? emails[0] : undefined;
    }

    if (!hint) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        user: null,
        waiting: false,
      };
      return;
    }

    loginSilent(user).then(
      (user) => {
        // eslint-disable-next-line no-console
        console.info("Obtained a new token silently");
        this.setState({
          user,
          waiting: false,
        });
      },
      () => {
        this.setState({
          user: null,
          waiting: false,
        });
      }
    );
  }

  onLogin(user: User): void {
    this.setState({
      user,
    });
  }

  render(): ReactElement {
    const {user, waiting} = this.state;

    if (waiting) {
      return <></>;
    }

    if (user === null) {
      return <Login onLogin={(user) => this.onLogin(user)} />;
    }

    // if the user is not enabled for the application, display the information
    if (!isEnabledForApp(user)) {
      return <UserNotSystemEnabled adminLogin={user.isAADUser()} />;
    }

    // if the user is an HCP, we need to fetch information about:
    // which organizations the HCP is enabled for, and which markets
    if (user.isB2CUser()) {
      // we need to fetch the user's context before displaying the web page;
      // note that if the user doesn't belong to any organization, the page
      // will not be displayed anyway
      return (
        <ServicesContext.Consumer>
          {(services) => (
            <HCPContextView user={user} services={services}>
              <UserContext.Provider value={user}>
                {this.props.children}
              </UserContext.Provider>
            </HCPContextView>
          )}
        </ServicesContext.Consumer>
      );
    }

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
