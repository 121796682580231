/* eslint-disable indent */
import {BaseURL} from ".";
import {get} from "../../common/fetch";
import {
  HearingCareProfessionalsAPI,
  HearingCareProfessionalContext,
} from "../domain/hcps";

export default class KiseiHearingCareProfessionalsAPI
  implements HearingCareProfessionalsAPI {
  async getUserContext(): Promise<HearingCareProfessionalContext> {
    return get<HearingCareProfessionalContext>(
      `${BaseURL}/api/hcp/own-context`
    );
  }
}
