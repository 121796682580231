import {User} from "../../service/user";

export function getDefaultCategory(user: User): string {
  const categoriesRoles = user.getCategoriesRoles();
  if (categoriesRoles.length === 1) {
    // most common scenario
    return categoriesRoles[0];
  }

  return "";
}

export function getDefaultCountry(user: User): string {
  const marketsRoles = user.getMarketsRoles();
  if (marketsRoles.length > 0) {
    // most common scenario, for LME
    return marketsRoles[0];
  }

  return "";
}
