import React, {Component, ReactElement} from "react";
import {ReleaseDetails} from "../../../service/domain/releases";
import FileDownloadLink from "./file-download-link";

export interface ReleaseFilesProps {
  details: ReleaseDetails;
  disableStats?: boolean;
  disableLinks?: boolean;
}

export default class ReleaseFiles extends Component<ReleaseFilesProps> {
  render(): ReactElement {
    const {details, disableLinks, disableStats} = this.props;

    if (disableLinks) {
      return (
        <ul className="files-list">
          {details.nodes.map((item) => {
            return <li key={item.id}>{item.node.name}</li>;
          })}
        </ul>
      );
    }

    return (
      <ul className="files-list">
        {details.nodes.map((item) => {
          return (
            <li key={item.id}>
              <FileDownloadLink
                releaseId={item.releaseId}
                node={item.node}
                stats={disableStats ? false : true}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
