import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {Chip} from "@material-ui/core";
import {Country} from "../../../service/domain/countries";
import {IServices} from "../../../service/services";

export interface CountriesSummaryProps {
  ids: string[];
  services: IServices;
}

export interface CountriesSummaryState {
  loading: boolean;
  countriesDictionary: {[key: string]: Country};
}

export default class CountriesSummary extends Component<
  CountriesSummaryProps,
  CountriesSummaryState
> {
  constructor(props: CountriesSummaryProps) {
    super(props);

    this.state = {
      loading: true,
      countriesDictionary: {},
    };
  }

  componentDidMount(): void {
    this.load();
  }

  async load(): Promise<void> {
    this.setState({
      loading: true,
    });
    const countries = await this.props.services.countries.getCountries();

    const dict: {[key: string]: Country} = {};

    for (const country of countries) {
      dict[country.id] = country;
    }

    this.setState({
      loading: false,
      countriesDictionary: dict,
    });
  }

  render(): ReactElement {
    const {loading, countriesDictionary} = this.state;
    const {ids} = this.props;

    if (loading) {
      return <Loader className="mini" />;
    }

    return (
      <div>
        {(ids || []).map((item) => (
          <Chip
            key={item}
            label={
              item in countriesDictionary
                ? countriesDictionary[item].name
                : item
            }
          />
        ))}
      </div>
    );
  }
}
