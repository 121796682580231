import React, {Component, ReactElement} from "react";
import Select from "@material-ui/core/Select";
import {getDefaultFormat} from "../../../common/format-date";

const DatesCulturePreferenceKey = "DATES_CULTURE";

interface Culture {
  name: string;
  datesFormat: string;
}

const SupportedCultures: Culture[] = [
  {
    name: "en-us",
    datesFormat: "MM/DD/YYYY",
  },
  {
    name: "en-gb",
    datesFormat: "DD/MM/YYYY",
  },
  {
    name: "ja-jp",
    datesFormat: "YYYY/MM/DD",
  },
];

interface Preferences {
  datesCulture: string;
}

// Basic, simple preferences page to handle date formats, using localStorage.
export default class PreferencesRegion extends Component<
  unknown,
  Preferences
> {
  constructor(props: unknown) {
    super(props);

    this.state = {
      datesCulture: "",
    };
  }

  onCultureSelect(event: React.ChangeEvent<{value: unknown}>): void {
    const value = event.target.value as string;

    localStorage.setItem(DatesCulturePreferenceKey, value);

    this.setState({
      datesCulture: value,
    });
  }

  readInitialValues(): Preferences {
    return {
      datesCulture:
        localStorage.getItem(DatesCulturePreferenceKey) || getDefaultFormat(),
    };
  }

  componentDidMount(): void {
    this.setState(this.readInitialValues());
  }

  render(): ReactElement {
    const {datesCulture} = this.state;

    return (
      <section>
        <h2>Preferences</h2>
        <dl>
          <dt>Dates format</dt>
          <dd>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={datesCulture}
              onChange={this.onCultureSelect.bind(this)}
              native
            >
              {SupportedCultures.map((item) => {
                return (
                  <option key={item.name} value={item.name}>
                    {item.name.toUpperCase()}&emsp;({item.datesFormat})
                  </option>
                );
              })}
            </Select>
          </dd>
        </dl>
      </section>
    );
  }
}
