import CountryFilter from "../countries/country-select";
import debounce from "lodash/debounce";
import NamedSelect from "../../common/forms/select-named";
import React, {Component, ReactElement} from "react";
import {Country} from "../../../service/domain/countries";
import {IServices} from "../../../service/services";
import TextInput from "../../common/forms/text-input";
import CategorySelect from "../categories/category-select";
import {Category} from "../../../service/domain/categories";

export interface ReleasesFiltersProps {
  search: string;
  draft: boolean | null;
  categoryId: string;
  countryId: string;
  services: IServices;
  onChange: (filters: ReleasesFiltersState) => void;
  onReady?: () => void;
  onCountriesLoaded: (items: Country[]) => void;
}

export interface ReleasesFiltersState {
  category: string;
  search: string;
  country: string;
  draft: boolean | null;
}

interface ReleaseStatus {
  id: string;
  name: string;
}

const STATUSES: ReleaseStatus[] = [
  {
    id: "draft",
    name: "DRAFT",
  },
  {
    id: "published",
    name: "PUBLISHED",
  },
];

export default class ReleasesFilters extends Component<
  ReleasesFiltersProps,
  ReleasesFiltersState
> {
  private countriesLoaded: boolean;
  private categoriesLoaded: boolean;

  constructor(props: ReleasesFiltersProps) {
    super(props);

    this.countriesLoaded = false;
    this.categoriesLoaded = false;

    this.state = {
      search: props.search,
      country: props.countryId,
      category: props.categoryId,
      draft: props.draft,
    };
  }

  onFiltersChange = debounce(
    () => {
      this.props.onChange(this.state);
    },
    250,
    {leading: false, trailing: true}
  );

  onCountrySelect(item: Country | null): void {
    const newId = item ? item.id : "";
    if (newId === this.state.country) {
      return;
    }

    this.setState({
      country: newId,
    });

    this.onFiltersChange();
  }

  onStatusSelect(item: ReleaseStatus | null): void {
    this.setState({
      draft: item ? item.id === "draft" : null,
    });

    this.onFiltersChange();
  }

  onSearchChange(search: string): void {
    if (search === this.props.search) {
      return;
    }
    this.setState({
      search,
    });

    this.onFiltersChange();
  }

  onCategoryChange(category: Category | null): void {
    // back-end supports category filter both by name and id
    const categoryId = category !== null ? category.name : "";

    if (categoryId === this.state.category) {
      return;
    }
    this.setState({
      category: categoryId,
    });

    this.onFiltersChange();
  }

  onCountriesLoaded(countries: Country[]): void {
    this.countriesLoaded = true;

    this.checkIfReady();
    this.props.onCountriesLoaded(countries);
  }

  onCategoriesLoaded(): void {
    this.categoriesLoaded = true;

    this.checkIfReady();
  }

  private checkIfReady(): void {
    if (this.countriesLoaded && this.categoriesLoaded && this.props.onReady) {
      this.props.onReady();
    }
  }

  render(): ReactElement {
    const {countryId, services} = this.props;
    const {search, draft} = this.state;

    let initialDraftValue: string | undefined = undefined;

    if (draft !== null) {
      initialDraftValue = draft ? "draft" : "published";
    }

    return (
      <div className="filters-region">
        <dl>
          <dt>
            <label>Search</label>
          </dt>
          <dd>
            <TextInput
              value={search}
              onChange={(value) => this.onSearchChange(value)}
            />
          </dd>
          <dt>Category</dt>
          <dd>
            <CategorySelect
              service={this.props.services.categories}
              value={this.props.categoryId}
              onChange={(value) => this.onCategoryChange(value)}
              onLoaded={this.onCategoriesLoaded.bind(this)}
              disallowEmpty={false}
            />
          </dd>
          <CountryFilter
            service={services.countries}
            onSelect={this.onCountrySelect.bind(this)}
            onLoaded={this.onCountriesLoaded.bind(this)}
            initialValue={countryId}
          />
          <dt>
            <label>Status</label>
          </dt>
          <dd>
            <NamedSelect<ReleaseStatus>
              items={STATUSES}
              onSelect={this.onStatusSelect.bind(this)}
              initialValue={initialDraftValue}
            />
          </dd>
        </dl>
      </div>
    );
  }
}
