import * as H from "history";
import CategorySelect from "../categories/category-select";
import CountriesSelect from "../countries/countries-select";
import Form from "../../common/forms/form";
import React, {Component, ReactElement} from "react";
import ReleaseDescriptionField from "./release-description-field";
import ReleaseNameField from "./release-name-field";
import {ApplicationError, ConflictError} from "../../../common/errors";
import {Button} from "@material-ui/core";
import {i} from "../../../locale";
import {IServices} from "../../../service/services";

export interface NewReleaseProps {
  services: IServices;
  history: H.History<H.LocationState>;
}

export interface NewReleaseState {
  waiting: boolean;
  error: ApplicationError | null;
}

export default class NewRelease extends Component<
  NewReleaseProps,
  NewReleaseState
> {
  private nameField: React.RefObject<ReleaseNameField>;
  private descriptionField: React.RefObject<ReleaseDescriptionField>;
  private countriesSelect: React.RefObject<CountriesSelect>;
  private categorySelect: React.RefObject<CategorySelect>;

  constructor(props: NewReleaseProps) {
    super(props);

    this.nameField = React.createRef();
    this.descriptionField = React.createRef();
    this.countriesSelect = React.createRef();
    this.categorySelect = React.createRef();

    this.state = {
      waiting: false,
      error: null,
    };
  }

  async validate(): Promise<boolean> {
    const results = await Promise.all([
      this.nameField.current?.validate(),
      this.descriptionField.current?.validate(),
    ]);

    return results.every((item) => item === true);
  }

  confirm(): void {
    const nameField = this.nameField.current;

    if (nameField) {
      nameField.user_interaction = true;
    }

    this.validate().then((valid) => {
      if (!valid) {
        return;
      }

      this.setState({
        error: null,
        waiting: true,
      });

      this.props.services.releases
        .createRelease({
          categoryId: this.categorySelect.current?.value.id || "",
          name: this.nameField.current?.value || "",
          description: this.descriptionField.current?.value || "",
          countries: this.countriesSelect.current?.ids || null,
        })
        .then(
          (data) => {
            this.setState({
              error: null,
              waiting: false,
            });

            this.props.history.push(`/release/${data.id}`);
          },
          (error: ApplicationError) => {
            if (error instanceof ConflictError) {
              this.nameField.current?.setError(
                "A release already exists with this name"
              );
              this.setState({
                waiting: false,
              });
              return;
            }
            error.retry = () => {
              this.confirm();
            };
            this.setState({
              error,
              waiting: false,
            });
          }
        );
    });
  }

  render(): ReactElement {
    const {waiting, error} = this.state;

    return (
      <Form waiting={waiting} error={error} onSubmit={() => this.confirm()}>
        <h2>{i().CreateNewRelease}</h2>
        <dl>
          <dt>{i().Name}</dt>
          <dd className="full-w">
            <ReleaseNameField ref={this.nameField} />
          </dd>
          <dt>Description</dt>
          <dd className="full-w">
            <ReleaseDescriptionField ref={this.descriptionField} />
          </dd>
          <dt>Category</dt>
          <dd>
            <CategorySelect
              service={this.props.services.categories}
              ref={this.categorySelect}
            />
          </dd>
          <dt>Countries</dt>
          <dd>
            <CountriesSelect
              service={this.props.services.countries}
              ref={this.countriesSelect}
            />
          </dd>
        </dl>
        <ul className="notes">
          <li>
            <p>
              A release is always created in <strong>DRAFT</strong> status. It
              can be modified after creation.
            </p>
          </li>
          <li>
            <p>You can upload files for the release after it`s created.</p>
          </li>
        </ul>
        <div className="buttons-area">
          <Button type="submit">{i().Confirm}</Button>
        </div>
      </Form>
    );
  }
}
