import React, {Component, ReactElement} from "react";

export interface AppInfoProps {
  version: string;
}

export default class AppInfo extends Component<AppInfoProps> {
  render(): ReactElement {
    const {version} = this.props;
    return (
      <>
        <span className="version">v.{version}</span>
      </>
    );
  }
}
