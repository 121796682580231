interface SettingsFile {
  api_url: string;
  client_id: string;
  client_authority: string;
  b2c_client_id: string;
  b2c_authority: string;
  registration_client_id: string;
  onecustomer_api_url: string;
  tenant: string;
  oticon_medical_id: string;
  invitation_redirect_url: string;
  password_reset_redirect_url: string;
  admin_portal_url: string;
}

// TODO: refactor the following code to make components testable,
// run the (window as any).SETTINGS hack only when using a browser!
// For example, create an instance of ServiceSettings and configure it in
// a custom context or in some way.

class ServiceSettings {
  private _apiURL: string;
  private _onecustomerURL: string;
  private _clientId: string;
  private _b2c_clientId: string;
  private _b2c_authority: string;
  private _registrationClientId: string;
  private _authority: string;
  private _redirectURL: string;
  private _tenant: string;
  private _invitationRedirectURL: string;
  private _passwordResetRedirectURL: string;
  private _oticonMedicalId: string;
  private _adminPortalUrl: string;

  public get apiURL(): string {
    return this._apiURL;
  }

  public get onecustomerURL(): string {
    return this._onecustomerURL;
  }

  public get registrationClientId(): string {
    return this._registrationClientId;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public get b2cClientId(): string {
    return this._b2c_clientId;
  }

  public get b2cAuthority(): string {
    return this._b2c_authority;
  }

  public get authority(): string {
    return this._authority;
  }

  public get redirectURL(): string {
    return this._redirectURL;
  }

  public get tenant(): string {
    return this._tenant;
  }

  public get passwordResetRedirectURL(): string {
    return this._passwordResetRedirectURL;
  }

  public get invitationRedirectURL(): string {
    return this._invitationRedirectURL;
  }

  public get oticonMedicalId(): string {
    return this._oticonMedicalId;
  }

  public get adminPortalUrl(): string {
    return this._adminPortalUrl;
  }

  constructor() {
    const data = (window as any).SETTINGS as SettingsFile;

    this._apiURL = data.api_url;
    this._onecustomerURL = data.onecustomer_api_url;
    this._registrationClientId = data.registration_client_id;
    this._clientId = data.client_id;
    this._authority = data.client_authority;
    this._redirectURL = window.location.origin;
    this._oticonMedicalId = data.oticon_medical_id;
    this._invitationRedirectURL = data.invitation_redirect_url;
    this._passwordResetRedirectURL = data.password_reset_redirect_url;
    this._tenant = data.tenant;
    this._b2c_clientId = data.b2c_client_id;
    this._b2c_authority = data.b2c_authority;
    this._adminPortalUrl = data.admin_portal_url;
  }
}

export default new ServiceSettings();
