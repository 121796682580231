import CategorySelect from "../categories/category-select";
import React, {ChangeEvent, Component, ReactElement} from "react";
import {changeHandler} from "../../../common/forms";
import {IServices} from "../../../service/services";
import {TextField} from "@material-ui/core";
import CountryFilter from "../countries/country-select";
import {Country} from "../../../service/domain/countries";
import {Category} from "../../../service/domain/categories";
import {debounce} from "lodash";

export interface OrganizationsFiltersProps {
  services: IServices;
  country?: string;
  category?: string;
  onChange: (filters: OrganizationsFiltersState) => void;
  onReady?: () => void;
}

export interface OrganizationsFiltersState {
  search: string;
  category: string;
  country: string;
}

export default class OrganizationsFilters extends Component<
  OrganizationsFiltersProps,
  OrganizationsFiltersState
> {
  constructor(props: OrganizationsFiltersProps) {
    super(props);

    this.state = {
      search: "",
      country: props.country || "",
      category: props.category || "",
    };
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    // user's interaction
    changeHandler.call(this, event);

    this.onFiltersChange();
  }

  onFiltersChange = debounce(
    () => {
      this.props.onChange(this.state);
    },
    250,
    {leading: false, trailing: true}
  );

  onCategoryChange(category: Category | null): void {
    const newCategoryId = category ? category.id : "";
    if (newCategoryId === this.state.category) {
      return;
    }
    this.setState({
      category: newCategoryId,
    });

    this.onFiltersChange();
  }

  onCountrySelect(country: Country | null): void {
    const newCountryId = country ? country.id : "";
    if (newCountryId === this.state.country) {
      return;
    }
    this.setState({
      country: newCountryId,
    });

    this.onFiltersChange();
  }

  render(): ReactElement {
    const {search} = this.state;

    return (
      <div className="filters-region">
        <dl>
          <dt>
            <label>Search</label>
          </dt>
          <dd>
            <TextField
              value={search}
              name="search"
              autoComplete="off"
              onChange={this.onChange.bind(this)}
            />
          </dd>
          <dt>Category</dt>
          <dd>
            <CategorySelect
              service={this.props.services.categories}
              onChange={this.onCategoryChange.bind(this)}
              disallowEmpty={false}
            />
          </dd>
          <CountryFilter
            service={this.props.services.countries}
            onSelect={this.onCountrySelect.bind(this)}
          />
        </dl>
      </div>
    );
  }
}
