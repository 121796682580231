import arrays from "../../../common/arrays";
import FileInput from "./file-input";
import FileUpload from "./file-upload";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {Button} from "@material-ui/core";
import {FileInfo} from "../../../common/files";
import {IServices} from "../../../service/services";
import {
  CreateNodeInput,
  ReleaseDetails,
} from "../../../service/domain/releases";
import {ciEquals} from "../../../common/string";
import ErrorPanel from "../error";

export interface UploadProps {
  releaseId: string;
  services: IServices;
  waiting?: boolean;
  error?: ApplicationError | null;
  onCancel: () => void;
  onUpload: (release: ReleaseDetails) => void;
  dismissError?: () => void;
  details: ReleaseDetails;
}

export interface UploadState {
  files: FileInfo[];
  uploading: boolean;
  done: boolean;
  error: ApplicationError | null;
  details: ReleaseDetails;
}

export default class Upload extends Component<UploadProps, UploadState> {
  private filesInput: React.RefObject<FileInput>;
  private filesUpload: {[key: string]: FileUpload | null};

  constructor(props: UploadProps) {
    super(props);

    this.state = {
      files: [],
      uploading: false,
      done: false,
      error: null,
      details: props.details,
    };
    this.filesInput = React.createRef();
    this.filesUpload = {};
  }

  onChange(): void {
    //
  }

  componentWillUnmount(): void {
    this.filesUpload = {};
  }

  onFilesSelected(files: FileInfo[]): void {
    this.checkDuplicates(files);

    this.setState({
      files,
    });
  }

  onRemoveClick(file: FileInfo): void {
    const {files} = this.state;
    arrays.remove(files, file);
    delete this.filesUpload[file.id];

    this.checkDuplicates(files);

    this.setState({
      files,
    });

    if (!files.length) this.clearFiles();
  }

  onClearSelectionClick(): void {
    this.setState({files: []});
    this.clearFiles();
  }

  onUploadMoreClick(): void {
    this.setState({files: [], done: false});
    this.clearFiles();
  }

  isValid(): boolean {
    const {files} = this.state;

    for (const file of files) {
      if (file.error) {
        return false;
      }
    }

    return true;
  }

  onUploadClick(): void {
    if (!this.isValid()) {
      return;
    }

    if (this.state.uploading) return;
    this.setState({uploading: true});

    const operations: Promise<CreateNodeInput | undefined>[] = [];

    for (const key in this.filesUpload) {
      const control = this.filesUpload[key];

      if (control && control.canStartUpload()) {
        operations.push(control.upload());
      }
    }

    Promise.all(operations).then(
      (data) => {
        if (data.every((item) => item === undefined)) {
          // all files failed uploading!
          this.setState({
            uploading: false,
            done: false,
            error: new ApplicationError("Upload failed.", 500),
          });
          return;
        }

        // now bind all uploaded files to the release
        const {releaseId} = this.props;

        this.props.services.releases
          .createNodes(
            releaseId,
            data.filter((item) => item !== undefined) as CreateNodeInput[]
          )
          .then(
            (data) => {
              // all files uploaded correctly
              this.setState({uploading: false, done: true, details: data});

              this.props.onUpload(data);
            },
            (error) => {
              this.setState({
                uploading: false,
                done: false,
                error,
              });
            }
          );
      },
      () => {
        // there is no need to display an error message here,
        // because child views handle this; the user can also cancel the
        // upload, so nothing is wrong
        this.setState({uploading: false});
      }
    );
  }

  clearFiles(): void {
    this.filesInput.current?.reset();
    this.filesUpload = {};
  }

  checkDuplicates(files: FileInfo[]): void {
    const {details} = this.state;

    for (const file of files) {
      const duplicate = files.find(
        (item) => ciEquals(item.name, file.name) && item !== file
      );
      const duplicateAmongExisting = details.nodes.find((node) =>
        ciEquals(node.node.name, file.name)
      );

      if (duplicate || duplicateAmongExisting) {
        file.error = duplicateAmongExisting
          ? "A file with this same name is already configured for the release."
          : "There is another file with this name.";
      } else {
        file.error = undefined;
      }
    }
  }

  onDrop(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    const {files} = this.state;

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === "file") {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file !== null) files.push(FileInfo.fromFile(file));
        }
      }
    } else {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        files.push(FileInfo.fromFile(event.dataTransfer.files[i]));
      }
    }

    this.checkDuplicates(files);

    this.setState({
      files,
    });
  }

  onDragOver(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
  }

  shouldEnableUpload(): boolean {
    const {files} = this.state;
    return (
      files.length > 0 &&
      files.some((file) => file.error !== undefined) === false
    );
  }

  render(): ReactElement {
    const {releaseId} = this.props;
    const {error, files, uploading, done} = this.state;
    const displayControlButtons = !uploading && !done && files.length > 0;
    const uploadEnabled = this.shouldEnableUpload();

    return (
      <div
        className="file-input-region"
        onDrop={this.onDrop.bind(this)}
        onDragOver={this.onDragOver.bind(this)}
      >
        <div className="controls">
          {done === false && (
            <FileInput
              onFilesSelected={this.onFilesSelected.bind(this)}
              ref={this.filesInput}
              multiple
            />
          )}
          {displayControlButtons && (
            <>
              <Button
                color="primary"
                onClick={() => this.onUploadClick()}
                disabled={!uploadEnabled}
              >
                <i className="fas fa-upload"></i>
                Upload
              </Button>
              <Button
                color="secondary"
                onClick={() => this.onClearSelectionClick()}
              >
                Clear Selection
              </Button>
              <Button onClick={() => this.props.onCancel()}>Cancel</Button>
            </>
          )}
          {done && (
            <>
              <Button onClick={() => this.onUploadMoreClick()}>
                Upload more files
              </Button>
              <Button onClick={() => this.props.onCancel()}>Close</Button>
            </>
          )}
          {done === false && displayControlButtons === false && (
            <Button onClick={() => this.props.onCancel()}>Close</Button>
          )}
          {done && <em>All file were uploaded.</em>}
          {uploading && <em>Upload in progress, do not close this window.</em>}
        </div>
        {files.map((file) => {
          return (
            <div key={file.id}>
              <FileUpload
                file={file}
                releaseId={releaseId}
                services={this.props.services}
                onRemoveClick={() => this.onRemoveClick(file)}
                ref={(ref) => (this.filesUpload[file.id] = ref)}
              />
            </div>
          );
        })}
        {files.length === 0 && (
          <div className="drop-zone">
            <p>Use the select button, or drag one or more files...</p>
          </div>
        )}
        {files.length !== 0 && error && <ErrorPanel error={error} />}
      </div>
    );
  }
}
