import Head from "../../common/head";
import React, {ReactElement} from "react";
import LastReleasesGallery from "./last-releases-gallery";
import {AuthRouteConfiguration} from "../routing";
import {i} from "../../../locale";
import {ServicesContext} from "../services-context";
import {Tabs} from "../../common/tabs";
import {User} from "../../../service/user";

function ReleasesList(): ReactElement {
  return (
    <React.Fragment>
      <Head title={i().Pages.Releases} />
      <ServicesContext.Consumer>
        {(services) => <LastReleasesGallery services={services} />}
      </ServicesContext.Consumer>
    </React.Fragment>
  );
}

export function getLastReleasesPage(user: User): () => ReactElement {
  const subRoutes: AuthRouteConfiguration[] = [
    {
      path: "/releases",
      name: i().Pages.Releases,
      exact: true,
      main: ReleasesList,
    },
  ];

  return (): ReactElement =>
    Tabs(
      subRoutes.filter((route) => route.auth === undefined || route.auth(user))
    );
}
