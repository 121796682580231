import Query from "../../common/query";
import {BaseURL} from ".";
import {del, get, getOptional, patch, post, put} from "../../common/fetch";
import {Node} from "../domain/nodes";
import {PaginatedSet} from "../domain/lists";
import {
  ReleasesAPI,
  CreateReleaseInput,
  CreateReleaseOutput,
  UpdateReleaseInput,
  ReleaseDetails,
  ReleaseTableItem,
  CreateNodeInput,
  DeleteNodeInput,
  PublishReleaseInput,
  FileURL,
  DownloadStats,
  DownloadTableItem,
  CurrentRelease,
  CreateOrganizationsBoundInput,
} from "../domain/releases";
import {cache} from "../../common/caching";

export default class KiseiReleasesAPI implements ReleasesAPI {
  async getReleases(
    category: string,
    page?: number,
    sortBy?: string,
    search?: string,
    country?: string,
    draft?: boolean
  ): Promise<PaginatedSet<ReleaseTableItem>> {
    const query = Query.write({
      category,
      page,
      search,
      sortBy,
      country,
      draft,
    });
    return get<PaginatedSet<ReleaseTableItem>>(
      `${BaseURL}/api/releases${query}`
    );
  }

  async getReleaseById(id: string): Promise<ReleaseDetails | null> {
    return getOptional<ReleaseDetails>(`${BaseURL}/api/releases/${id}`);
  }

  async createRelease(
    input: CreateReleaseInput
  ): Promise<CreateReleaseOutput> {
    return post<CreateReleaseOutput>(`${BaseURL}/api/releases`, input);
  }

  async updateRelease(input: UpdateReleaseInput): Promise<ReleaseDetails> {
    return patch<ReleaseDetails>(`${BaseURL}/api/releases/${input.id}`, input);
  }

  async bindReleaseToOrganizations(
    releaseId: string,
    data: CreateOrganizationsBoundInput
  ): Promise<ReleaseDetails> {
    return put<ReleaseDetails>(
      `${BaseURL}/api/releases/${releaseId}/orgs`,
      data
    );
  }

  async unbindReleaseFromOrganizations(
    releaseId: string,
    data: string[]
  ): Promise<ReleaseDetails> {
    return del<ReleaseDetails>(
      `${BaseURL}/api/releases/${releaseId}/orgs`,
      data
    );
  }

  async createNodes(
    releaseId: string,
    input: CreateNodeInput[]
  ): Promise<ReleaseDetails> {
    return put<ReleaseDetails>(
      `${BaseURL}/api/releases/${releaseId}/files`,
      input
    );
  }

  async deleteNodes(
    releaseId: string,
    input: DeleteNodeInput[]
  ): Promise<ReleaseDetails> {
    return del<ReleaseDetails>(
      `${BaseURL}/api/releases/${releaseId}/files`,
      input
    );
  }

  async publishRelease(input: PublishReleaseInput): Promise<ReleaseDetails> {
    return post<ReleaseDetails>(
      `${BaseURL}/api/releases/${input.id}/publish`,
      input
    );
  }

  async getFileURL(releaseId: string, node: Node): Promise<FileURL> {
    return get<FileURL>(
      `${BaseURL}/api/releases/${releaseId}/file/${node.id}`
    );
  }

  async getFileDownloadStats(
    releaseId: string,
    node: Node
  ): Promise<DownloadStats> {
    return get<DownloadStats>(
      `${BaseURL}/api/releases/${releaseId}/file/${node.id}/downloads`
    );
  }

  @cache(1000)
  async getDownloads(
    page?: number,
    sortBy?: string,
    search?: string,
    country?: string,
    organizationId?: string
  ): Promise<PaginatedSet<DownloadTableItem>> {
    const query = Query.write({
      page,
      search,
      sortBy,
      country,
      organizationId,
    });
    return get<PaginatedSet<DownloadTableItem>>(
      `${BaseURL}/api/downloads${query}`
    );
  }

  async getCurrentReleases(): Promise<CurrentRelease[]> {
    return get<CurrentRelease[]>(`${BaseURL}/api/current-releases`);
  }

  async getCurrentReleasesByOrganizations(): Promise<CurrentRelease[]> {
    return get<CurrentRelease[]>(`${BaseURL}/api/orgs/current-releases`);
  }

  async clone(releaseId: string): Promise<ReleaseDetails> {
    return post<ReleaseDetails>(`${BaseURL}/api/releases/${releaseId}/clone`);
  }
}
