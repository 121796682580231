import React, {Component, ReactElement} from "react";
import {InterfaceError} from "../../../common/errors";
import {HearingCareProfessionalContext} from "../../../service/domain/hcps";
import NamedSelect, {NamedItem} from "../../common/forms/select-named";

export interface HCPOrganizationSelectProps {
  context: HearingCareProfessionalContext;
  onOrganizationSelect: (organization: NamedItem) => void;
}

export default class HCPOrganizationSelect extends Component<HCPOrganizationSelectProps> {
  onItemSelect(item: NamedItem | null): void {
    if (item === null) {
      throw new InterfaceError("The UI should require a selected item.");
    }

    this.props.onOrganizationSelect(item);
  }

  render(): ReactElement {
    const {context} = this.props;

    // TODO: store the initial value in memory

    const organizations: NamedItem[] = context.membership
      .filter((item) => item.role === "Admin")
      .map((item) => {
        return {
          id: item.organizationId,
          name: `${item.organizationName} (${item.organizationNumber})`,
        };
      });

    return (
      <NamedSelect<NamedItem>
        items={organizations}
        initialValue={organizations[0].id}
        onSelect={this.onItemSelect.bind(this)}
        disallowEmpty={true}
      />
    );
  }
}
