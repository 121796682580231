import {PaginatedSet} from "../domain/lists";
import {
  InvitationTableItem,
  InvitationsAPI,
  InvitationInput,
  InvitationOutput,
} from "../domain/invitations";
import {get, del, post} from "../../common/fetch";
import Query from "../../common/query";
import {OneCustomerBaseURL as BaseURL} from ".";

export default class OneCustomerInvitationsAPI implements InvitationsAPI {
  getInvitations(
    page: number,
    sortBy: string,
    search: string,
    brandId: string,
    organizationId: string
  ): Promise<PaginatedSet<InvitationTableItem>> {
    const query = Query.write({
      page,
      search,
      brand: brandId,
      organization: organizationId,
      sortBy: sortBy,
      role: "Joined", // TODO: support also fetching Admin invitations?
    });

    return get<PaginatedSet<InvitationTableItem>>(
      `${BaseURL}/invitations${query}`
    );
  }

  deleteInvitation(id: string): Promise<void> {
    return del<void>(`${BaseURL}/invitations/${id}`);
  }

  sendInvitation(input: InvitationInput): Promise<InvitationOutput> {
    return post<InvitationOutput>(
      `${BaseURL}/organizations/${input.organizationId}/invite`,
      input
    );
  }
}
