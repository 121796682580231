import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {DefaultFetchSettings} from "../../../common/fetch";
import {HearingCareProfessionalContext as HCPContextData} from "../../../service/domain/hcps";
import {IServices} from "../../../service/services";
import settings from "../../../service/settings";
import {User} from "../../../service/user";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import UserNotSystemEnabled from "../not-system-enabled";

export const HCPContext = React.createContext<HCPContextData | null>(null);

interface HCPContextProps {
  user: User;
  services: IServices;
}

interface HCPContextState {
  context: HCPContextData | null;
  waiting: boolean;
  error: ApplicationError | null;
}

export class HCPContextView extends Component<
  HCPContextProps,
  HCPContextState
> {
  constructor(props: HCPContextProps) {
    super(props);

    this.state = {
      context: null,
      waiting: true,
      error: null,
    };
  }

  async load(): Promise<void> {
    try {
      const {services} = this.props;
      const context = await services.hcps.getUserContext();

      // TODO: implement a cleaner way to do the following, to keep the
      // component abstracted from web requests (to be done when there is more
      // time for this project)
      // configure fetch settings to send the right access token
      // by target API
      DefaultFetchSettings.getCustomHeaders = (
        url
      ): {[key: string]: string} => {
        if (typeof url === "string") {
          if (url.indexOf(settings.onecustomerURL) > -1) {
            return {"HCP-Context": context.oneCustomerSignature};
          }
          if (url.indexOf(settings.apiURL) > -1) {
            return {"HCP-Context": context.kiseiSignature};
          }
        }
        return {};
      };

      this.setState({
        context,
        waiting: false,
      });
    } catch (error) {
      this.setState({
        waiting: false,
        error,
      });
    }
  }

  componentDidMount(): void {
    this.load();
  }

  render(): ReactElement {
    const {user} = this.props;
    const {context, waiting, error} = this.state;

    if (waiting) {
      return <Loader />;
    }

    if (error || context == null) {
      return <ErrorPanel error={error} />;
    }

    if (!context || !context.membership || context.membership.length === 0) {
      // This means that the user was enabled for the system (has B2C roles),
      // but was removed from the organizations
      //
      // TODO: remove the kisei_role claim when the user is
      //       removed from all organizations enabled for kisei_role!
      return <UserNotSystemEnabled adminLogin={false} />;
    }

    if (
      user.hasRole("HCP-Admin") &&
      context.membership.filter((item) => item.role === "Admin").length === 0
    ) {
      // This means that the user is still configured as HCP Admin by kisei_role,
      // but was removed from all organizations that was administering
      //
      // TODO: remove HCP-Admin value from kisei_role claim when the user is
      //       removed from all organizations as ADMIN!
      return <UserNotSystemEnabled adminLogin={false} />;
    }

    return (
      <HCPContext.Provider value={context}>
        {this.props.children}
      </HCPContext.Provider>
    );
  }
}
