import * as H from "history";
import Auth from "../../common/auth";
import Copy from "../../common/copy";
import CountriesSummary from "../countries/countries-summary";
import ErrorPanel from "../../common/error";
import formatDate from "../../../common/format-date";
import Loader from "../../common/loader";
import OrganizationsSummary from "../organizations/organizations-summary";
import React, {Component, ReactElement} from "react";
import ReleaseFiles from "./release-files";
import ReleaseHistoryView from "./release-history";
import {ApplicationError} from "../../../common/errors";
import {Button, IconButton} from "@material-ui/core";
import {delay} from "lodash";
import {DialogSize} from "../../common/dialogs/size";
import {dismissDialog} from "../view-functions";
import {IServices} from "../../../service/services";
import {ReleaseDetails} from "../../../service/domain/releases";
import {User} from "../../../service/user";
import ConfirmDialog, {
  closedDialog,
  ConfirmDialogProps,
} from "../../common/dialogs/confirm-dialog";

export interface ReleaseSummaryProps {
  details: ReleaseDetails;
  services: IServices;
  history: H.History<H.LocationState>;
  user: User;
}

export interface ReleaseSummaryState {
  confirm: ConfirmDialogProps;
  loading: boolean;
  error?: ApplicationError;
}

export default class ReleaseSummary extends Component<
  ReleaseSummaryProps,
  ReleaseSummaryState
> {
  constructor(props: ReleaseSummaryProps) {
    super(props);

    this.state = {
      confirm: closedDialog(),
      loading: false,
    };
  }

  onShowHistoryClick(): void {
    const {details, services} = this.props;

    this.setState({
      confirm: {
        open: true,
        title: "Release history",
        size: DialogSize.medium,
        fragment: (
          <ReleaseHistoryView releaseId={details.id} services={services} />
        ),
        close: () => {
          dismissDialog(this);
        },
      },
    });
  }

  async confirmClone(): Promise<void> {
    const {details, services} = this.props;
    if (this.state.loading) return;

    this.setState({
      loading: true,
    });

    try {
      const cloned = await services.releases.clone(details.id);

      delay(() => {
        this.props.history.push(`/release/${cloned.id}`);
      }, 50);

      this.setState({
        loading: false,
      });
    } catch (error: any) {
      this.setState({
        loading: false,
        error,
      });
    }
  }

  onCloneClick(): void {
    this.setState({
      confirm: {
        open: true,
        title: "Clone this release?",
        description:
          "If you clone this release, a new copy will be created in draft" +
          " status. The cloned release will reference the same files " +
          "and countries of this release, but can be edited after creation.",
        close: () => {
          dismissDialog(this);
        },
        confirm: () => {
          this.confirmClone();
        },
      },
    });
  }

  render(): ReactElement {
    const {details, user} = this.props;
    const {confirm, loading, error} = this.state;
    const isAdmin = user.isOMHQ();
    return (
      <div className="release-summary">
        {loading && <Loader className="covering" />}
        <h1>{details.name}</h1>
        <p>{details.description}</p>
        <dl>
          <dt>ID</dt>
          <dd>
            <Copy text={details.id} />
          </dd>
          <dt>Published at</dt>
          <dd>{formatDate(details.updateTime)}</dd>
          <dt>Category</dt>
          <dd>{details.category?.name}</dd>
          <dt>Countries</dt>
          <dd>
            <CountriesSummary
              ids={details.countries.map((bound) => bound.countryId)}
              services={this.props.services}
            />
          </dd>
          <dt>Notification</dt>
          <dd>
            {details.requestedNotification === true && <>Yes</>}
            {details.requestedNotification !== true && <>No</>}
          </dd>
          {details.organizations.length > 0 && (
            <>
              <dt>Organizations</dt>
              <dd>
                <OrganizationsSummary items={details.organizations} />
              </dd>
            </>
          )}
          <dt>Files</dt>
          <dd>
            <ReleaseFiles
              details={details}
              disableLinks={!isAdmin}
              disableStats={!isAdmin}
            />
          </dd>
          <Auth role="OMHQ">
            <dt>History</dt>
            <dd>
              <IconButton
                onClick={() => this.onShowHistoryClick()}
                title="Show history"
              >
                <i className="fas fa-history"></i>
              </IconButton>
            </dd>
          </Auth>
        </dl>
        {error && <ErrorPanel error={error} />}
        <Auth role="OMHQ">
          <div className="buttons-area">
            <Button type="button" onClick={() => this.onCloneClick()}>
              Clone
            </Button>
          </div>
        </Auth>
        <ConfirmDialog {...confirm} />
      </div>
    );
  }
}
