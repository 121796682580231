import Head from "../../common/head";
import InvitationsTable from "./invitations-table";
import NewInvitationForm from "./invitation-new";
import React, {ReactElement} from "react";
import {RouteConfiguration} from "../../../common/routing";
import {Tabs} from "../../common/tabs";
import {ServicesContext} from "../services-context";
import {HCPContext} from "../account/hcp-context";

function SentInvitations() {
  return (
    <>
      <Head title="Sent invitations" />
      <HCPContext.Consumer>
        {(context) =>
          context !== null && (
            <ServicesContext.Consumer>
              {(services) => (
                <InvitationsTable services={services} context={context} />
              )}
            </ServicesContext.Consumer>
          )
        }
      </HCPContext.Consumer>
    </>
  );
}

function NewInvitation() {
  return (
    <>
      <Head title="New invitation" />
      <HCPContext.Consumer>
        {(context) =>
          context !== null && (
            <ServicesContext.Consumer>
              {(services) => (
                <NewInvitationForm services={services} context={context} />
              )}
            </ServicesContext.Consumer>
          )
        }
      </HCPContext.Consumer>
    </>
  );
}

const subRoutes: RouteConfiguration[] = [
  {
    path: "/invitations",
    name: "Sent invitations",
    exact: true,
    main: SentInvitations,
  },
  {
    path: "/invitations/new",
    name: "New invitation",
    exact: false,
    main: NewInvitation,
  },
];

export default function InvitationsPage(): ReactElement {
  return Tabs(subRoutes);
}
