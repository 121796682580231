import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, {Component, ReactElement} from "react";

export default class UnsupportedBrowserCourtesyView extends Component {
  render(): ReactElement {
    return (
      <Grid container component="main" className="login-screen">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className="login-image" />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <div className="login-banner">
            <div>
              <img id="oticon-medical-logo" src="/oticon-medical-logo.svg" />
              <span className="app-name">Software Center</span>
            </div>
          </div>
          <div className="courtesy-paper jss3">
            <p>
              Dear guest,
              <br />
              Internet Explorer is not supported by this system. Please visit
              this website using a more modern browser, like Chrome, Edge,
              Firefox, or Opera. If this is not an option, please contact
              <a href="mailto:info@oticonmedical.com">
                &nbsp;info@oticonmedical.com
              </a>{" "}
              for assistance.
            </p>
            <p>Sorry for the inconvenience.</p>
          </div>
        </Grid>
      </Grid>
    );
  }
}
