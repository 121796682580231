import Query from "../../common/query";
import {
  HCPRole,
  EmployeesAPI,
  EmployeeTableItem,
  PersonInformation,
} from "../domain/employees";
import {OneCustomerBaseURL as BaseURL} from ".";
import {del, get} from "../../common/fetch";
import {PaginatedSet} from "../domain/lists";

export default class OneCustomerEmployeesAPI implements EmployeesAPI {
  getEmployees(
    page: number,
    sortBy: string,
    search: string,
    brandId: string,
    organizationId: string,
    role: HCPRole
  ): Promise<PaginatedSet<EmployeeTableItem>> {
    const query = Query.write({
      page,
      search,
      brand: brandId,
      organization: organizationId,
      sortBy: sortBy,
      role: role,
    });

    return get<PaginatedSet<EmployeeTableItem>>(
      `${BaseURL}/employees${query}`
    );
  }

  removeEmployee(id: string): Promise<void> {
    return del<void>(`${BaseURL}/employees/${id}`);
  }

  getPersonInformation(personId: string): Promise<PersonInformation> {
    return get<PersonInformation>(`${BaseURL}/person/${personId}`);
  }
}
