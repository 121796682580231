import {isString} from "lodash";
import isNumber from "lodash/isNumber";

const supportedFormats: {[key: string]: Intl.DateTimeFormat} = {
  "en-us": new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }),
  "en-gb": new Intl.DateTimeFormat("en-gb", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }),
  "ja-jp": new Intl.DateTimeFormat("ja-jp", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }),
};

export function getDefaultFormat(): string {
  if (/en-us/i.test(window.navigator.language)) {
    return "en-us";
  }
  return "en-gb";
}

function getCurrentFormat(): Intl.DateTimeFormat {
  const defaultFormat = getDefaultFormat();
  const preferredDateCulture =
    localStorage.getItem("DATES_CULTURE") || defaultFormat;
  return (
    supportedFormats[preferredDateCulture] || supportedFormats[defaultFormat]
  );
}

export default function formatDate(
  input: Date | string | number | null
): string {
  if (!input) {
    return "";
  }
  if (isNumber(input)) {
    input = new Date(input);
  }
  // NB: + "Z" here is fine because our API stores and returns dates in UTC
  if (isString(input) && !input.endsWith("Z")) {
    input = input + "Z";
  }
  const value = input instanceof Date ? input : new Date(input);
  try {
    return getCurrentFormat().format(value);
  } catch (error) {
    // it doesn't make sense to crash a whole component only because of
    // a failure in formatting a date

    // eslint-disable-next-line no-console
    console.error(error);
    return "";
  }
}
