import Head from "../common/head";
import React, {Component, ReactElement} from "react";
import {Typography} from "@material-ui/core";
import {b2c_logout} from "./auth";

interface NotEnabledProps {
  adminLogin: boolean;
}

export default class UserNotSystemEnabled extends Component<NotEnabledProps> {
  async logout(): Promise<void> {
    await b2c_logout();
    window.location.reload();
  }

  componentDidMount(): void {
    // no need to keep the ID_TOKEN
    localStorage.removeItem("ID_TOKEN");
  }

  render(): ReactElement {
    const {adminLogin} = this.props;
    return (
      <div id="not-authorized-view">
        <Head title="Not authorized" />
        <div className="bar-contents">
          <img id="oticon-medical-logo" src="/oticon-medical-logo.svg" />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className="headline"
          >
            <span>Software Center</span>
          </Typography>
        </div>
        <main>
          <div id="content-area">
            <h1>You are not authorized to use the system</h1>
            {adminLogin && (
              <p>
                Your sign-in was successful, but you are not authorized to use
                this system. If you think you should be authorized, please
                create a service desk ticket to request administrative rights.
              </p>
            )}
            {adminLogin === false && (
              <p>
                Your sign-in was successful, but you are not authorized to use
                this system. If you think you should be authorized, please
                contact a representative and ask to be invited to use the
                system.
              </p>
            )}
            <hr />
            <button className="fake-anchor" onClick={() => this.logout()}>
              Return to the sign-in screen.
            </button>
          </div>
        </main>
      </div>
    );
  }
}
