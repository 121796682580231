import {BaseURL} from ".";
import {cache} from "../../common/caching";
import {get} from "../../common/fetch";
import {Category, CategoriesAPI} from "../domain/categories";

export default class KiseiCategoriesAPI implements CategoriesAPI {
  @cache()
  async getCategories(): Promise<Category[]> {
    return get<Category[]>(`${BaseURL}/api/categories`);
  }
}
