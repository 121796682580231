import debounce from "lodash/debounce";
import React, {Component, ReactElement} from "react";
import TextInput from "../../common/forms/text-input";
import {NamedItem} from "../../common/forms/select-named";
import HCPOrganizationSelect from "./hcp-organization-select";
import {HearingCareProfessionalContext} from "../../../service/domain/hcps";

export interface HCPsFiltersProps {
  search: string;
  organizationId: string;
  onChange: (filters: HCPsFiltersState) => void;
  context: HearingCareProfessionalContext;
}

export interface HCPsFiltersState {
  search: string;
  organizationId: string;
}

export default class HCPsFilters extends Component<
  HCPsFiltersProps,
  HCPsFiltersState
> {
  constructor(props: HCPsFiltersProps) {
    super(props);

    this.state = {
      search: props.search,
      organizationId: props.organizationId,
    };
  }

  onFiltersChange = debounce(
    () => {
      this.props.onChange(this.state);
    },
    250,
    {leading: false, trailing: true}
  );

  onOrganizationSelect(item: NamedItem | null): void {
    const currentValue = this.state.organizationId;
    const newValue = item ? item.id : "";
    if (currentValue === newValue) {
      return;
    }
    this.setState({
      organizationId: newValue,
    });

    this.onFiltersChange();
  }

  onSearchChange(search: string): void {
    const currentValue = this.state.search;
    if (currentValue === search) {
      return;
    }
    this.setState({
      search,
    });

    this.onFiltersChange();
  }

  render(): ReactElement {
    const {context} = this.props;
    const {search} = this.state;

    return (
      <div className="filters-region">
        <dl>
          <dt>
            <label>Search</label>
          </dt>
          <dd>
            <TextInput
              value={search}
              onChange={(value) => this.onSearchChange(value)}
            />
          </dd>
          <dt>Organization</dt>
          <dd>
            <HCPOrganizationSelect
              context={context}
              onOrganizationSelect={this.onOrganizationSelect.bind(this)}
            ></HCPOrganizationSelect>
          </dd>
        </dl>
      </div>
    );
  }
}
