import {IconButton} from "@material-ui/core";
import React, {Component, ReactElement} from "react";

export interface CollapsibleProps {
  title: string;
  initialCollapsed?: boolean;
}

export interface CollapsibleState {
  collapsed: boolean;
}

function getCollapsibleTitle(collapsed: boolean): string {
  return collapsed ? "Click to expand" : "Click to collapse";
}

export default class Collapsible extends Component<
  CollapsibleProps,
  CollapsibleState
> {
  constructor(props: CollapsibleProps) {
    super(props);

    this.state = {
      collapsed: props.initialCollapsed || false,
    };
  }

  toggleCollapsed(): void {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render(): ReactElement {
    const {title} = this.props;
    const {collapsed} = this.state;

    return (
      <div
        className={
          "collapsible-section " + (collapsed ? "collapsed" : "expanded")
        }
      >
        <div className="header-section">
          <IconButton
            onClick={() => this.toggleCollapsed()}
            title={getCollapsibleTitle(collapsed)}
          >
            {collapsed === true && <i className="fas fa-chevron-down"></i>}
            {collapsed === false && <i className="fas fa-chevron-up"></i>}
          </IconButton>
        </div>
        <div className="content-section">
          <h2
            className="collapsible-title"
            onClick={() => this.toggleCollapsed()}
          >
            {title}
            {collapsed === true && "…"}
          </h2>
          <div className="collapsible-items">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
