import formatDate from "../../../common/format-date";
import memory from "../../../common/memory";
import query from "../../../common/query";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import DownloadsFilters, {DownloadsFiltersState} from "./downloads-filters";
import {DownloadTableItem} from "../../../service/domain/releases";
import {IServices} from "../../../service/services";
import {PaginatedSet} from "../../../service/domain/lists";
import GenericDialog, {
  GenericDialogProps,
  closedDialog,
} from "../../common/dialogs/generic-dialog";
import {
  DynamicTable,
  ItemAction,
  ItemProperty,
} from "../../common/tables/tables";
import {Link} from "react-router-dom";
import {User} from "../../../service/user";
import {delay} from "lodash";
import Copy from "../../common/copy";

export interface DownloadsProps {
  services: IServices;
  user: User;
}

export interface DownloadsState {
  loading: boolean;
  error?: ApplicationError;
  downloads: DownloadTableItem[];
  filters: DownloadsFiltersState;
  dialog: GenericDialogProps;
  canDisplayTable: boolean;
}

function getProperties(): ItemProperty<DownloadTableItem>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: DownloadTableItem) => {
        return item.id;
      },
    },
    {
      id: "userEmail",
      label: "User email",
      render: (item: DownloadTableItem) => {
        return <Copy text={item.userEmail} />;
      },
    },
    {
      id: "fileName",
      label: "File name",
      render: (item: DownloadTableItem) => {
        return item.node.name;
      },
    },
    {
      id: "release",
      label: "Release",
      notSortable: true,
      render: (item: DownloadTableItem) => {
        return (
          <Link to={"/release/" + item.releaseId}>
            <em title={item.releaseId}>{item.releaseId.substring(0, 6)}…</em>
          </Link>
        );
      },
    },
    {
      id: "timeStamp",
      label: "Timestamp",
      render: (item: DownloadTableItem) => {
        return formatDate(item.timeStamp);
      },
    },
  ];
}

function exportTransform(item: DownloadTableItem): any {
  return {
    id: item.id,
    nodeId: item.nodeId,
    releaseId: item.releaseId,
    userEmail: item.userEmail,
    userId: item.userId,
    timeStamp: item.timeStamp,
    accessGrantedByOrganizationId: item.accessGrantedByOrganizationId,
  };
}

export default class Downloads extends Component<
  DownloadsProps,
  DownloadsState
> {
  constructor(props: DownloadsProps) {
    super(props);

    const qs =
      memory.read<DownloadsFiltersState>("DOWNLOADS_FILTERS") ||
      query.getAll();

    this.state = {
      loading: true,
      downloads: [],
      filters: {
        search: qs.search,
        country: qs.country,
        organizationId: qs.organizationId || null,
      },
      dialog: closedDialog(),
      canDisplayTable: false,
    };
  }

  fetch(
    page: number,
    sortBy: string,
    filters: DownloadsFiltersState
  ): Promise<PaginatedSet<DownloadTableItem>> {
    return this.props.services.releases.getDownloads(
      page,
      sortBy,
      filters.search,
      filters.country,
      filters.organizationId || undefined
    );
  }

  onFiltersChange(filters: DownloadsFiltersState): void {
    this.setState({
      filters,
    });
    query.storeInHash(filters);
    memory.write("DOWNLOADS_FILTERS", filters);
  }

  getActions(): Array<ItemAction<DownloadTableItem>> {
    return [];
  }

  onCountriesLoaded(): void {
    // TODO: it wasn't immediately apparent that we need to fetch the list of
    // user countries before displaying the table (because countries can be
    // filtered by user's role and we want to automatically select the country
    // when the user is a LME!)

    // TODO: for all scenarios where a specific country (or more in general
    // a specific item) needs to be selected, it would be best to fetch all
    // information before displaying the filters view, instead of fetching
    // data dynamically.
    delay(() => {
      this.setState({
        canDisplayTable: true,
      });
    }, 10);
  }

  render(): ReactElement {
    const {services, user} = this.props;
    const {filters, dialog, canDisplayTable} = this.state;
    const properties = getProperties();

    return (
      <div>
        <div className="presentation">
          <h1>Downloads overview</h1>
          {user.isB2CUser() ? (
            <p>
              Here you can see the overview of all downloads made by HCPs in
              your organization. Note that this table includes all downloads
              information, also for the past releases.
            </p>
          ) : (
            <p>
              Here you can see the overview of all downloads made for all files
              across releases and countries.
            </p>
          )}
        </div>
        <DownloadsFilters
          services={services}
          search={filters.search}
          countryId={filters.country}
          onChange={this.onFiltersChange.bind(this)}
          onCountriesLoaded={() => this.onCountriesLoaded()}
        />
        {canDisplayTable && (
          <DynamicTable<DownloadTableItem, DownloadsFiltersState>
            items={[]}
            defaultSortOrder="desc"
            defaultSortProperty="timeStamp"
            properties={properties}
            filters={filters}
            provider={this.fetch.bind(this)}
            actions={this.getActions()}
            exportTransform={exportTransform}
            exportFileName="downloads.xlsx"
          />
        )}
        <GenericDialog {...dialog} />
      </div>
    );
  }
}
