import React, {Component, ReactElement} from "react";
import {Chip} from "@material-ui/core";
import {ReleaseOrganization} from "../../../service/domain/releases";
import settings from "../../../service/settings";

export interface OrganizationsSummaryProps {
  items: ReleaseOrganization[];
}

export default class OrganizationsSummary extends Component<OrganizationsSummaryProps> {
  render(): ReactElement {
    const {items} = this.props;

    return (
      <div>
        {(items || []).map((item) => (
          <Chip
            component="a"
            key={item.organizationId}
            label={item.displayName || item.organizationId}
            href={`${settings.adminPortalUrl}/organizations/${item.organizationId}`}
            clickable
          />
        ))}
      </div>
    );
  }
}
