import CountriesSummary from "../countries/countries-summary";
import formatDate from "../../../common/format-date";
import memory from "../../../common/memory";
import query from "../../../common/query";
import React, {Component, ReactElement} from "react";
import ReleaseHistory from "./release-history";
import ReleasesFilters, {ReleasesFiltersState} from "./releases-filters";
import {ApplicationError} from "../../../common/errors";
import {delay} from "lodash";
import {DialogSize} from "../../common/dialogs/size";
import {dismissGenericDialog} from "../view-functions";
import {
  getDefaultCategory,
  getDefaultCountry,
} from "../../common/user-functions";
import {IServices} from "../../../service/services";
import {PaginatedSet} from "../../../service/domain/lists";
import {Release, ReleaseTableItem} from "../../../service/domain/releases";
import {User} from "../../../service/user";
import GenericDialog, {
  GenericDialogProps,
  closedDialog,
} from "../../common/dialogs/generic-dialog";
import {
  DynamicTable,
  ItemAction,
  ItemProperty,
} from "../../common/tables/tables";
import OrganizationsSummary from "../organizations/organizations-summary";
import {Country, getCountriesDict} from "../../../service/domain/countries";

export interface ReleasesProps {
  services: IServices;
  user: User;
}

export interface ReleasesState {
  loading: boolean;
  error?: ApplicationError;
  filters: ReleasesFiltersState;
  releases: Release[];
  dialog: GenericDialogProps;
  canDisplayTable: boolean;
}

function getProperties(table: Releases): ItemProperty<ReleaseTableItem>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: ReleaseTableItem) => {
        return item.id;
      },
    },
    {
      id: "draft",
      label: "Status",
      render: (item: ReleaseTableItem) => {
        return item.draft ? "DRAFT" : "PUBLISHED";
      },
    },
    {
      id: "category",
      label: "Category",
      render: (item: ReleaseTableItem) => {
        return item.categoryId;
      },
    },
    {
      id: "name",
      label: "Name",
      render: (item: ReleaseTableItem) => {
        return item.name;
      },
    },
    {
      id: "description",
      label: "Description",
      render: (item: ReleaseTableItem) => {
        return item.description;
      },
    },
    {
      id: "creationTime",
      label: "Creation time",
      render: (item: ReleaseTableItem) => {
        return formatDate(item.creationTime);
      },
    },
    {
      id: "updateTime",
      label: "Last update time",
      render: (item: ReleaseTableItem) => {
        return formatDate(item.updateTime);
      },
    },
    {
      id: "countries",
      label: "Countries",
      notSortable: true,
      render: (item: ReleaseTableItem) => {
        return (
          <CountriesSummary
            ids={(item.countries || []).map((item) => item.countryId)}
            services={table.props.services}
          />
        );
      },
    },
    {
      id: "organizations",
      label: "Organizations",
      notSortable: true,
      render: (item: ReleaseTableItem) => {
        return <OrganizationsSummary items={item.organizations} />;
      },
    },
  ];
}

export default class Releases extends Component<ReleasesProps, ReleasesState> {
  private countriesDict: {
    [key: string]: Country;
  };

  constructor(props: ReleasesProps) {
    super(props);

    // restore from query, or memory
    // TODO: if restored from memory, it should be written in the page query!
    const qs =
      memory.read<ReleasesFiltersState>("RELEASES_FILTERS") || query.getAll();

    if (!qs.category) {
      qs.category = getDefaultCategory(props.user);
    }

    if (!qs.country) {
      qs.country = getDefaultCountry(props.user);
    }

    this.countriesDict = {};

    this.state = {
      loading: true,
      releases: [],
      filters: {
        category: qs.category,
        search: qs.search,
        country: qs.country,
        draft: "draft" in qs && qs.draft !== null ? qs.draft === "true" : null,
      },
      dialog: closedDialog(),
      canDisplayTable: false,
    };
  }

  fetch(
    page: number,
    sortBy: string,
    filters: ReleasesFiltersState
  ): Promise<PaginatedSet<ReleaseTableItem>> {
    return this.props.services.releases.getReleases(
      filters.category,
      page,
      sortBy,
      filters.search,
      filters.country,
      filters.draft === null ? undefined : filters.draft
    );
  }

  onFiltersChange(filters: ReleasesFiltersState): void {
    this.setState({
      filters,
    });
    query.storeInHash(filters);
    memory.write("RELEASES_FILTERS", filters);
  }

  onShowHistoryClick(item: ReleaseTableItem): void {
    this.setState({
      dialog: {
        open: true,
        title: "Release history",
        size: DialogSize.medium,
        fragment: (
          <ReleaseHistory releaseId={item.id} services={this.props.services} />
        ),
        close: () => {
          dismissGenericDialog(this);
        },
        buttons: [
          {
            id: "close",
            label: "Close",
            onClick: () => {
              dismissGenericDialog(this);
            },
          },
        ],
      },
    });
  }

  getActions(): Array<ItemAction<ReleaseTableItem>> {
    return [
      {
        title: "Show history",
        icon: <i className="fas fa-history"></i>,
        onClick: this.onShowHistoryClick.bind(this),
      },
    ];
  }

  onFiltersReady(): void {
    // TODO: it wasn't immediately apparent that we need to fetch the list of
    // user countries before displaying the table (because countries can be
    // filtered by user's role and we want to automatically select the country
    // when the user is a LME!)

    // TODO: for all scenarios where a specific country (or more in general
    // a specific item) needs to be selected, it would be best to fetch all
    // information before displaying the filters view, instead of fetching
    // data dynamically.
    delay(() => {
      this.setState({
        canDisplayTable: true,
      });
    }, 10);
  }

  exportTransform(item: ReleaseTableItem): any {
    return {
      id: item.id,
      name: item.name,
      categoryId: item.categoryId,
      description: item.description,
      status: item.draft ? "DRAFT" : "PUBLISHED",
      countries: (item.countries || [])
        .map((item) =>
          item.countryId in this.countriesDict
            ? this.countriesDict[item.countryId].name
            : item.countryId
        )
        .join("; "),
      organizations: (item.organizations || [])
        .map((item) => `${item.displayName} (${item.organizationId})`)
        .join("; "),
      creationTime: item.creationTime,
      updateTime: item.updateTime,
    };
  }

  render(): ReactElement {
    const {services} = this.props;
    const {filters, dialog, canDisplayTable} = this.state;
    const properties = getProperties(this);

    return (
      <div>
        <ReleasesFilters
          services={services}
          draft={filters.draft}
          search={filters.search}
          countryId={filters.country}
          categoryId={filters.category}
          onChange={this.onFiltersChange.bind(this)}
          onCountriesLoaded={(items) =>
            (this.countriesDict = getCountriesDict(items))
          }
          onReady={this.onFiltersReady.bind(this)}
        />
        {canDisplayTable && (
          <DynamicTable<ReleaseTableItem, ReleasesFiltersState>
            items={[]}
            defaultSortOrder="desc"
            defaultSortProperty="updateTime"
            detailsRoute="/release/"
            properties={properties}
            filters={filters}
            provider={this.fetch.bind(this)}
            actions={this.getActions()}
            exportTransform={this.exportTransform.bind(this)}
            exportFileName="releases.xlsx"
          />
        )}
        <GenericDialog {...dialog} />
      </div>
    );
  }
}
