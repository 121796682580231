import DownloadsTable from "./releases/downloads-table";
import Head from "../common/head";
import React, {ReactElement} from "react";
import {AuthRouteConfiguration} from "./routing";
import {i} from "../../locale";
import {ServicesContext} from "./services-context";
import {Tabs} from "../common/tabs";
import {User} from "../../service/user";
import {UserContext} from "./user-context";

function DownloadsList(): ReactElement {
  return (
    <React.Fragment>
      <Head title={i().Pages.Downloads} />
      <UserContext.Consumer>
        {(user) => (
          <ServicesContext.Consumer>
            {(services) => <DownloadsTable services={services} user={user} />}
          </ServicesContext.Consumer>
        )}
      </UserContext.Consumer>
    </React.Fragment>
  );
}

export function getDownloadsPage(user: User): () => ReactElement {
  const subRoutes: AuthRouteConfiguration[] = [
    {
      path: "/downloads",
      name: i().Pages.Downloads,
      exact: true,
      main: DownloadsList,
    },
  ];

  return (): ReactElement =>
    Tabs(
      subRoutes.filter((route) => route.auth === undefined || route.auth(user))
    );
}
