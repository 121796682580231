import React, {Component, ReactElement} from "react";
import Pagination from "@material-ui/lab/Pagination";

export interface PaginationBarProps {
  page: number;
  defaultPageSize?: number;
  itemsCount: number;
  onPageChange: (page: number) => void;
}

interface PaginationBarState {
  pageCount: number;
  pageSize: number;
}

export class PaginationBar extends Component<
  PaginationBarProps,
  PaginationBarState
> {
  pageSize = 30;

  constructor(props: PaginationBarProps) {
    super(props);

    if (isNaN(props.page)) {
      throw new Error("Invalid page number");
    }

    const defaultPageSize = props.defaultPageSize ?? 30;

    this.state = {
      pageCount: this.getPageCount(props.itemsCount, this.pageSize),
      pageSize: defaultPageSize,
    };
  }

  componentDidUpdate(newProps: PaginationBarProps): void {
    if (newProps.itemsCount !== this.props.itemsCount) {
      this.setState({
        pageCount: this.getPageCount(this.props.itemsCount, this.pageSize),
      });
    }
  }

  get firstObjectNumber(): number {
    const {page} = this.props;
    const {pageSize} = this.state;
    return page * pageSize - pageSize + 1;
  }

  get lastObjectNumber(): number {
    const {itemsCount, page} = this.props;
    const {pageSize} = this.state;
    return Math.min(itemsCount, page * pageSize);
  }

  get resultsFromXtoYofT(): string {
    return (
      `Results ${this.firstObjectNumber} - ${this.lastObjectNumber} ` +
      `of ${this.props.itemsCount}`
    );
  }

  getPageCount(objectsCount: number, objectsPerPage: number): number {
    // Made by ROPT (long time ago, before joining Demant)
    if (objectsCount === Infinity) return Infinity;
    if (objectsCount === -Infinity) return 0;
    if (objectsCount < 1) return 1;
    if (objectsCount > objectsPerPage) {
      if (objectsCount % objectsPerPage === 0) {
        return objectsCount / objectsPerPage;
      }
      return Math.ceil(objectsCount / objectsPerPage);
    }
    return 1;
  }

  onChange(event: React.ChangeEvent<unknown>, value: number): void {
    this.props.onPageChange(value);
  }

  render(): ReactElement {
    const props = this.props;

    const {page} = props;
    const {pageCount} = this.state;

    return (
      <div>
        <Pagination
          size="medium"
          page={props.page}
          count={pageCount}
          showFirstButton
          showLastButton
          onChange={this.onChange.bind(this)}
        />
        {props.children}
        {props.itemsCount === 1 && (
          <div className="pagination-info">
            <span className="page-x-of-y">1 result</span>
          </div>
        )}
        {props.itemsCount > 1 && (
          <div className="pagination-info">
            <span className="page-x-of-y">
              Page {page} of {pageCount}
            </span>
            <span className="results-x-to-y-of-t">
              {this.resultsFromXtoYofT}
            </span>
          </div>
        )}
      </div>
    );
  }
}
