import KiseiCategoriesAPI from "./api/categories";
import KiseiCountriesAPI from "./api/countries";
import KiseiFilesAPI from "./api/blobs";
import KiseiHearingCareProfessionalsAPI from "./api/hcps";
import KiseiReleasesAPI from "./api/releases";
import OneCustomerAccountAPI from "./api/account";
import OneCustomerEmployeesAPI from "./api/employees";
import OneCustomerInvitationsAPI from "./api/invitations";
import OneCustomerOrganizationsAPI from "./api/organizations";
import OneCustomerPersonsAPI from "./api/persons";
import {AccountAPI} from "./domain/account";
import {AppInfoAPI} from "./domain/info";
import {CategoriesAPI} from "./domain/categories";
import {CountriesAPI} from "./domain/countries";
import {EmployeesAPI} from "./domain/employees";
import {FilesAPI} from "./domain/blobs";
import {HearingCareProfessionalsAPI} from "./domain/hcps";
import {InvitationsAPI} from "./domain/invitations";
import {OrganizationsAPI} from "./domain/organizations";
import {PersonsAPI} from "./domain/persons";
import {ReleasesAPI} from "./domain/releases";
import KiseiAppInfoAPI from "./api/info";

export interface IServices {
  blobs: FilesAPI;
  releases: ReleasesAPI;
  categories: CategoriesAPI;
  countries: CountriesAPI;
  account: AccountAPI;
  hcps: HearingCareProfessionalsAPI;
  employees: EmployeesAPI;
  persons: PersonsAPI;
  invitations: InvitationsAPI;
  organizations: OrganizationsAPI;
  info: AppInfoAPI;
}

export const Services: IServices = {
  blobs: new KiseiFilesAPI(),
  releases: new KiseiReleasesAPI(),
  categories: new KiseiCategoriesAPI(),
  countries: new KiseiCountriesAPI(),
  account: new OneCustomerAccountAPI(),
  hcps: new KiseiHearingCareProfessionalsAPI(),
  employees: new OneCustomerEmployeesAPI(),
  persons: new OneCustomerPersonsAPI(),
  invitations: new OneCustomerInvitationsAPI(),
  organizations: new OneCustomerOrganizationsAPI(),
  info: new KiseiAppInfoAPI(),
};
