import React, {Component, ReactElement} from "react";
import {IServices} from "../../../service/services";
import {ServicesContext} from "../services-context";
import {downloadURL} from "../../../common/files";
import {Node} from "../../../service/domain/nodes";
import {IconButton} from "@material-ui/core";
import {DownloadStats} from "../../../service/domain/releases";

export interface FileDownloadLinkProps {
  releaseId: string;
  node: Node;
  stats?: boolean;
}

export interface FileDownloadLinkState {
  info: DownloadStats | null;
}

export default class FileDownloadLink extends Component<
  FileDownloadLinkProps,
  FileDownloadLinkState
> {
  constructor(props: FileDownloadLinkProps) {
    super(props);

    this.state = {
      info: null,
    };
  }

  async onClick(services: IServices): Promise<void> {
    // Note: a simple anchor element with download attribute
    // does not work, because the API is protected by JWT Bearer authentication
    // It would work if we used cookie based authentication, since cookies
    // are sent by default at each web request.
    const {releaseId, node} = this.props;
    try {
      const data = await services.releases.getFileURL(releaseId, node);
      downloadURL(data.url, node.name);
    } catch (error) {
      // TODO: handle with UI
      alert("An error occurred!");
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async showDownloadStats(services: IServices): Promise<void> {
    const {releaseId, node} = this.props;
    const info = await services.releases.getFileDownloadStats(releaseId, node);

    this.setState({
      info,
    });
  }

  renderDownloadsInfo(info: DownloadStats): ReactElement {
    const uniqueDownloads = info.uniqueDownloads;

    if (uniqueDownloads === 0) {
      return <em className="note">never downloaded</em>;
    }

    if (uniqueDownloads === 1) {
      return <em className="note">{uniqueDownloads} unique download</em>;
    }

    return <em className="note">{uniqueDownloads} unique downloads</em>;
  }

  render(): ReactElement {
    const {node, stats} = this.props;
    const {info} = this.state;
    return (
      <ServicesContext.Consumer>
        {(services) => (
          <>
            <button
              type="button"
              className="fake-anchor"
              onClick={() => this.onClick(services)}
            >
              {node.name}
            </button>
            {info !== null && this.renderDownloadsInfo(info)}
            {info === null && stats && (
              <IconButton
                onClick={() => this.showDownloadStats(services)}
                className="collapse"
              >
                <i className="far fa-question-circle"></i>
              </IconButton>
            )}
          </>
        )}
      </ServicesContext.Consumer>
    );
  }
}
