import React, {Component, ReactElement} from "react";
import {ReleaseOrganization} from "../../../service/domain/releases";
import {
  ItemAction,
  ItemProperty,
  StaticTable,
} from "../../common/tables/tables";

export interface ReleaseOrganizationsTableProps {
  items: ReleaseOrganization[];
  actions?: Array<ItemAction<ReleaseOrganization>>;
}

export interface ReleaseOrganizationTableItem extends ReleaseOrganization {
  id: string;
}

function convertToTableItems(
  items: ReleaseOrganization[]
): ReleaseOrganizationTableItem[] {
  return items.map((item: any) => {
    // this is a dirty hack, but still better than mapping each property
    // manually
    item.id = item.releaseId + "-" + item.organizationId;
    return item as ReleaseOrganizationTableItem;
  });
}

function getProperties(): ItemProperty<ReleaseOrganizationTableItem>[] {
  return [
    {
      id: "organizationId",
      label: "Organization id",
      notSortable: true,
      render: (item: ReleaseOrganizationTableItem) => {
        return item.organizationId;
      },
    },
    {
      id: "displayName",
      label: "Organization name",
      notSortable: true,
      render: (item: ReleaseOrganizationTableItem) => {
        return item.displayName;
      },
    },
  ];
}

// eslint-disable-next-line max-len
export default class ReleaseOrganizationsTable extends Component<ReleaseOrganizationsTableProps> {
  render(): ReactElement {
    const {actions, items} = this.props;

    return (
      <StaticTable<ReleaseOrganizationTableItem>
        properties={getProperties()}
        defaultSortOrder="asc"
        defaultSortProperty="name"
        actions={actions || []}
        items={convertToTableItems(items)}
      />
    );
  }
}
